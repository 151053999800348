import { useQuery } from "@tanstack/react-query";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { EventPromoterCode, PromoterCode } from "../../../types/PromoterCode";
import { Button } from "../../../components/reusable/Button";
import { Fragment, useCallback, useState } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { useNotification } from "../../../providers/NotificationContext";
import { Menu, Transition } from "@headlessui/react";
import { CheckCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import { HappyEvent } from "../../../types/Event";
import { PlusIcon } from "@heroicons/react/24/outline";

export const PromoterCodes = ({event}: {event: HappyEvent}) => {
  const params = useParams();
  const eventId = params.id;
  const { showSuccess, showError } = useNotification();
  const { currentOrganization } = useVendor();
  const [showAddPromoterCodeModal, setShowAddPromoterCodeModal] =
    useState(false);

  const { data: promoterCodeData, refetch: refetchPromoterCodes } = useQuery({
    queryKey: ["promoterCodes", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/promoter_codes`,
        params: {
          organization_id: currentOrganization?.id,
          event_id: eventId,
        },
      }),
    enabled: !!currentOrganization && !!eventId,
  });

  const promoterCodes: PromoterCode[] = promoterCodeData?.data || [];

  const { data: eventPromoterCodeData, refetch: refetchEventPromoterCodes } =
    useQuery({
      queryKey: ["eventPromoterCodes", currentOrganization],
      queryFn: () =>
        makeApiRequest({
          path: `/vendor/event_promoter_codes`,
          params: {
            organization_id: currentOrganization?.id,
            event_id: eventId,
          },
        }),
      enabled: !!currentOrganization && !!eventId,
    });

  const eventPromoterCodes: EventPromoterCode[] =
    eventPromoterCodeData?.data || [];
  const promoterCodesForEvent = eventPromoterCodes.map(
    (epc) => epc.promoter_code
  );
  const attachCode = (promoter_code_id: number) => {
    makeApiRequest({
      method: "POST",
      path: `/vendor/event_promoter_codes`,
      params: {
        event_id: eventId,
        promoter_code_id,
        organization_id: currentOrganization?.id,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Promoter code attached successfully");
        }

        refetchEventPromoterCodes();
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  };

  return (
    <div>
      <VendorCard className="mt-5">
        <div className="flex items-center justify-between mb-5">
          <h1 className="text-2xl font-bold">Promoter Codes</h1>
          <Button
            onClick={() => setShowAddPromoterCodeModal(true)}
            variant="blue"
            size="sm"
          >
            <PlusIcon className="w-4" />
            Attach Promoter Code
          </Button>
        </div>
        <PromoterCodeTable
          event={event}
          refetchCodes={refetchEventPromoterCodes}
          eventPromoterCodes={eventPromoterCodes}
        />
      </VendorCard>

      <Modal
        isOpen={showAddPromoterCodeModal}
        title="Add Promoter Code to Organization"
        onRequestClose={() => setShowAddPromoterCodeModal(false)}
      >
        <>
        {promoterCodes.length ? (

          <div className="">
            <ul className="flex flex-col divide-y-2 divide-gray-100">
              {promoterCodes.map((pc) => (
                <li className="flex items-center py-2 justify-between w-full">
                  <span>
                    {pc.promoter} - Code: <b>{pc.code}</b>
                  </span>
                  {promoterCodesForEvent.map((p) => p.id).includes(pc.id) ? (
                    <CheckCircleIcon className="w-7 text-green-400" />
                  ) : (
                    <Button onClick={() => attachCode(pc.id)} size="sm">
                      Attach Code
                    </Button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div>No promoter codes available</div>
            <Link to={`/vendor/organizations/${currentOrganization?.id}/promoter-codes`} className="text-blue-500">
              <Button variant="blue" size="sm">
                Create a New Promoter Code
              </Button>
            </Link>
          </div>
        
        )}
        </>
      </Modal>
    </div>
  );
};

export const PromoterCodeTable = ({
  eventPromoterCodes,
  refetchCodes,
  event
}: {
  event: HappyEvent
  refetchCodes: () => void;
  eventPromoterCodes: EventPromoterCode[];
}) => {
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<EventPromoterCode>();
  const { showSuccess, showError } = useNotification();
  const copyToClipboard = useCallback((code: string) => {
    navigator.clipboard
      .writeText(
        `${import.meta.env.VITE_CONSUMER_WEB_URL}/events/${event?.slug}?promoter_code=${code}`
      )
      .then(() => {
        showSuccess("Event URL successfully copied to clipboard.");
      })
      .catch((err) => {
        // Failed to copy
        console.error("Failed to copy text: ", err);
      });
  }, []);

  const columns = [
    columnHelper.accessor("promoter_code", {
      header: "Code",
      cell: (info) => {
        const code: PromoterCode = info.getValue();
        return <span>{code.code}</span>;
      },
    }),
    columnHelper.accessor("promoter_code", {
      header: "Promoter",
      cell: (info) => {
        const code: PromoterCode = info.getValue();
        return <span>{code.promoter}</span>;
      },
    }),
    columnHelper.accessor("total_orders", {
      header: "Total Orders",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("total_tickets", {
      header: "Total Tickets",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="block"
                      onClick={() => copyToClipboard(props.row.original.promoter_code.code)}
                    >
                      Copy Event URL
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to detach this promoter code from this event?"
                          )
                        ) {
                          deleteEventPromoterCode(props.row.original.id);
                        }
                      }}
                    >
                      Delete
                    </span>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: eventPromoterCodes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const deleteEventPromoterCode = (id: number) => {
    makeApiRequest({
      method: "DELETE",
      path: `/vendor/event_promoter_codes/${id}`,
      params: {
        organization_id: currentOrganization?.id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess("Promoter code detached successfully");
          refetchCodes();
        }
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  };

  if (!eventPromoterCodes) {
    return <div>Loading...</div>;
  }

  if (eventPromoterCodes.length === 0) {
    return <div>No promoter codes currently being used for this event.</div>;
  }
  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="text-gray-500 text-sm" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11 text-sm" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1 text-gray-400" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
