import React from "react";
import { classNames } from "../../utils/styles";
// import cn from 'classnames'

// const ButtonVariantStyles = {
//   white: 'inline-flex items-center justify-center rounded border border-mono-400 bg-mono-white px-10 h-12 text-mono-700 shadow-sm hover:bg-mono-100 focus:outline-none focus:ring-2 focus:ring-light-blue focus:ring-offset-2',
//   gray: 'inline-flex items-center justify-center rounded border border-dark-blue-gray bg-blue-gray px-10 h-12 text-mono-700 shadow-sm hover:bg-dark-blue-gray focus:outline-none focus:ring-2 focus:ring-light-blue focus:ring-offset-2',
//   blueOutline: 'inline-flex items-center justify-center rounded-lg border-2 border-light-blue bg-transparent px-10 h-12 text-light-blue shadow-sm hover:bg-mono-100 focus:outline-none focus:ring-2 focus:ring-light-blue focus:ring-offset-2',
//   pillPrimary: 'inline-flex justify-center items-center px-10 h-12 border border-transparent text-base text-mono-white rounded-full shadow-sm text-white bg-light-blue hover:bg-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue',
//   pillSecondary: 'inline-flex justify-center items-center px-10 h-12 border border-light-blue text-base rounded-full shadow-sm text-light-blue bg-transparent hover:bg-mono-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue',
//   link: 'inline-flex items-center text-light-blue bg-transparent border-0 font-normal',
// }

const ButtonVariantStyles = {
  purple:
    "hover:brightness-110 inline-flex items-center justify-center bg-purple text-white h-10",
  success:
    "hover:brightness-110 inline-flex items-center justify-center bg-green-500 text-white h-10",
  error:
    "hover:brightness-110 inline-flex items-center justify-center bg-red-500 text-white h-10",
  pink: "hover:brightness-110 inline-flex items-center justify-center bg-pink text-white h-10",
  blue: "hover:brightness-110 inline-flex items-center justify-center bg-blue text-white h-10",
  yellow:
    "hover:brightness-110 inline-flex items-center justify-center bg-yellow text-white h-10",
  link: "hover:brightness-90 inline-flex items-center text-pink bg-transparent border-0 font-extrabold",
  default:
    "inline-flex items-center justify-center bg-white h-10 hover:bg-gray-200 text-gray-500 border-gray-200 text-gray-200 border-2",
};

const sizeStyles = {
  sm: "text-sm px-3 h-8",
  md: "text-base px-5 h-10",
  lg: "text-lg px-8 h-12",
};

const baseStyles = `leading-5 active:translate-y-0.5 rounded-md flex items-center gap-2`;
const loadingStyles = "cursor-default";
const disableStyles =
  "text-gray-500 bg-gray-300 border-gray-300 cursor-not-allowed inline-flex items-center justify-center";

type Props = {
  size?: "sm" | "md" | "lg";
  variant?: keyof typeof ButtonVariantStyles;
  loading?: boolean;
  children?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {
      size = "md",
      children,
      type,
      disabled,
      loading = false,
      variant = "default",
      className,
      ...baseAttribs
    } = props;

    const style = classNames(
      baseStyles,
      disabled ? disableStyles : ButtonVariantStyles[variant],
      loading ? loadingStyles : "",
      variant !== 'link' ? sizeStyles[size] : '',
      className || ""
    );
    return (
      <button
        ref={ref}
        className={style}
        disabled={disabled || loading}
        type={type}
        {...baseAttribs}
      >
        {loading && "Loading..."}
        {children}
      </button>
    );
  }
);
