import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../utils/api";
import { useCallback, useEffect, useState } from "react";
import {
  BuildingOffice2Icon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Venue } from "../types/Venue";
import { User } from "../types/User";
import { Organization } from "../types/Organization";
import { BuildingStorefrontIcon, StarIcon } from "@heroicons/react/24/solid";
import { debounce, result } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { HappyEvent } from "../types/Event";
import { Combobox } from "@headlessui/react";
export type SearchResult = {
  organizations: Organization[];
  events: HappyEvent[];
  users: User[];
};

type ResultItem = HappyEvent | User | Organization;

export const SearchResultContent = ({ item }: { item: ResultItem }) => {
  if (item.__type === "HappyEvent") {
    return (
      <span>
        <div className="flex justify-start w-full items-start relative pt-8">
          <span className="absolute top-3 left-0 items-center flex text-gray-400 text-xs italic">
            Event
          </span>
          <div>
            <h3 className="text-lightPurple">{item.name}</h3>
            <span className="text-gray-400 text-sm">
              {item.city}, {item.state}
            </span>
          </div>
        </div>
      </span>
    );
  }

  if (item.__type === "Organization") {
    return (
      <span>
        <div className="flex justify-start w-full items-start relative pt-8">
          <span className="absolute top-3 left-0 items-center flex text-lightPurple text-xs italic">
            Org
          </span>
          <div>
            <h3 className="text-lightPurple">{item.name}</h3>
            <span className="text-gray-400 text-sm">{item.address_1}</span>
          </div>
        </div>
      </span>
    );
  }

  if (item.__type === "User") {
    return (
      <span>
        <div className="flex justify-start w-full items-start relative pt-8">
          <span className="absolute top-3 left-0 items-center flex text-gray-400 text-xs italic">
            User
          </span>
          <div>
            <h3 className="text-lightPurple">
              {item.first_name} {item.last_name}
            </h3>
            <span className="text-gray-400 text-sm">{item.email}</span>
          </div>
        </div>
      </span>
    );
  }

  return <></>;
};

export const SearchBar = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<ResultItem | null | undefined>(null);
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["search"],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/search",
        params: { search },
      }),
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const results: SearchResult = data?.data;

  const debouncedSearch = debounce(() => {
    refetch();
  }, 600);

  const happySearch = useCallback(debouncedSearch, []);

  useEffect(() => {
    if (search && search.length > 0 && !isFetching) {
      happySearch();
    }

    if (search.length === 0) {
      console.log("clearing");
      setSelectedItem(null);
    }
  }, [search]);

  const handleResultClick = (item: ResultItem) => {
    let url = `/admin`
    setSelectedItem(item);
    if (item.__type === "HappyEvent") {
      url = `/admin/events/${item.id}`;
    }

    if (item.__type === "Organization") {
      url = `/admin/organizations/${item.id}`;
    }

    if (item.__type === "User") {
      url = `/admin/users/${item.id}`;
    }

    navigate(url);
  }

  return (
    <Combobox value={selectedItem} onChange={(value: ResultItem | null) => handleResultClick(value as ResultItem)}>
      <div className="relative flex items-center flex-1">
        <label htmlFor="search-field" className="sr-only">
          Search
        </label>
        <MagnifyingGlassIcon
          className="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-gray-400"
          aria-hidden="true"
        />
        <Combobox.Input
          autoComplete="off"
          id="search-field"
          className="bg-input/10 rounded-md block h-10 w-full lg:w-96 border-0 py-0 pl-8 pr-0 text-gray-100 placeholder:text-placeholder focus:ring-0 focus:outline-none sm:text-sm"
          placeholder="Search Users, Organizations, and Events..."
          type="search"
          name="happy search"
          displayValue={(item: ResultItem) => {
            if (item?.__type === "HappyEvent") {
              return item.name;
            }

            if (item?.__type === "Organization") {
              return item.name;
            }

            if (item?.__type === "User") {
              return `${item.first_name} ${item.last_name}`;
            }

            return "";
          
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Combobox.Options className="divide-y divide-white/10 overflow-auto bg-deepPurple shadow sm:rounded-md fixed z-10 top-16 lg:w-1/4 max-h-80">
          {results && search.length > 0 &&
            [...results.events, ...results.users, ...results.organizations].map(
              (result: ResultItem) => (
                <Combobox.Option className="hover:bg-white/10 cursor-pointer px-4 py-4 sm:px-6" value={result} key={result.id}>
                  <SearchResultContent item={result} />
                </Combobox.Option>
              )
            )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};
