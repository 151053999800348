import { useEffect, useState } from "react";
import { Button } from "../../../components/reusable/Button";
import DatePicker from "react-datepicker";
import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { Venue } from "../../../types/Venue";
import {
  CameraIcon,
  CheckCircleIcon,
  CurrencyDollarIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { format } from "date-fns";
import {
  BuilderPriceLevel,
  BuilderSection,
  useEventBuilder,
} from "../../../providers/EventBuilderContext";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { formatCentsToDollars } from "../../../utils/currency";
import {
  ArrowRightIcon,
  CalendarIcon,
  DocumentDuplicateIcon,
  TicketIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { CloudArrowUpIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useNotification } from "../../../providers/NotificationContext";
import { Spinner } from "../../../components/reusable/Spinner";
import { dataURLtoBlob } from "../../../utils/images";
import { times, uniqBy } from "lodash";
import { NewVenueForm } from "../Venues/VenueList";
import { TierWizardInput } from "./TierWizardInput";
import { TextInput } from "../../../components/reusable/Form";
import { SeasonPassGroup } from "../../../types/SeasonPass";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { HappyEvent, PriceLevel } from "../../../types/Event";
export const EventBuilder = () => {
  return (
    <div className="w-full pb-20">
      <h3 className="text-4xl font-bold text-gray-500 mb-10">
        <span className="font-milkshake text-pink">Happy</span>{" "}
        <small>Event Builder</small>
      </h3>

      <Routes>
        <Route path="/" element={<BasicBuilder />} />
        <Route path="/advanced" element={<AdvancedBuilder />} />
      </Routes>
    </div>
  );
};

export const BasicBuilder = () => {
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const setActiveStep = (step: number) => {
    dispatch({ type: "goToStep", payload: { activeStep: step } });
  };

  const backgroundImage = eventBuilderState.image?.image?.toString();
  const valid =
    eventBuilderState.name && eventBuilderState.start && eventBuilderState.end;

  return (
    <>
      <div className="flex w-1/2 items-center gap-4">
        <Button
          onClick={() => setActiveStep(1)}
          variant={eventBuilderState.activeStep === 1 ? "purple" : "default"}
          size="sm"
        >
          Details
        </Button>
        <div className="border-t border-gray-300 flex-1"></div>
        <Button
          disabled={!valid}
          onClick={() => setActiveStep(2)}
          variant={eventBuilderState.activeStep === 2 ? "purple" : "default"}
          size="sm"
        >
          Venue
        </Button>
        <div className="border-t border-gray-300 flex-1"></div>
        <Button
          disabled={!valid}
          onClick={() => setActiveStep(3)}
          variant={eventBuilderState.activeStep === 3 ? "purple" : "default"}
          size="sm"
        >
          Pricing
        </Button>
        <div className="border-t border-gray-300 flex-1"></div>
        <Button
          disabled={!valid}
          onClick={() => setActiveStep(4)}
          variant={eventBuilderState.activeStep === 4 ? "purple" : "default"}
          size="sm"
        >
          Tickets
        </Button>
      </div>

      <div className="flex justify-between">
        <div className="w-1/2">
          <VendorCard className="mt-10">
            {eventBuilderState.activeStep === 1 && (
              <>
                <EventName />
                <EventDate />
                <div className="w-full border-b border-gray-300 my-6" />
                <EventPhotoUpload />
                <Button
                  disabled={!valid}
                  onClick={() => setActiveStep(2)}
                  className="h-16 w-full mt-8"
                  variant="pink"
                >
                  Continue
                </Button>
              </>
            )}
            {eventBuilderState.activeStep === 2 && (
              <>
                <VenueSelect />
                <Button
                  disabled={!valid}
                  onClick={() => setActiveStep(3)}
                  className="h-16 w-full mt-8"
                  variant="pink"
                >
                  Continue
                </Button>
              </>
            )}
            {eventBuilderState.activeStep === 3 && <BasicPricing />}
            {eventBuilderState.activeStep === 4 && <BasicTickets />}
          </VendorCard>
        </div>
        <div className="w-1/2 flex justify-center relative mt-10">
          <div className="w-[256px] h-[628px] flex flex-col items-center sticky top-20">
            <div
              style={
                backgroundImage
                  ? {
                      background: `url('${backgroundImage}')no-repeat center center`,
                      backgroundSize: "cover",
                    }
                  : {}
              }
              className="w-[250px] mt-0.5 h-44 bg-purple static z-30 flex items-center justify-center"
            >
              {!backgroundImage && (
                <img
                  className="w-24 h-24"
                  src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
                />
              )}
            </div>
            <div className="p-4 static z-30 flex flex-col w-full">
              <h3 className="font-bold text-xl text-gray-500">
                {eventBuilderState.name || "Your Event Name"}
              </h3>

              <div className="flex flex-col gap-2 mt-5 text-gray-500">
                <div className="flex flex-col">
                  <span>
                    <b>Starts:</b>
                  </span>
                  {eventBuilderState.start && (
                    <span>
                      {format(
                        new Date(eventBuilderState.start || ""),
                        "E, MMM do @ h:mm a"
                      )}
                    </span>
                  )}
                </div>

                <div className="flex flex-col">
                  <span>
                    <b>Ends:</b>
                  </span>
                  {eventBuilderState.end && (
                    <span>
                      {format(
                        new Date(eventBuilderState.end || ""),
                        "E, MMM do @ h:mm a"
                      )}
                    </span>
                  )}
                </div>

                <div className="flex flex-col">
                  <span>
                    <b>Venue:</b>
                  </span>
                  <span>
                    {eventBuilderState.primaryVenue?.name || "Your Venue"}
                  </span>
                </div>
              </div>
              <span className="font-milkshake text-lightPurple w-full text-center absolute bottom-1/4 pb-2 left-0 right-0">
                Happy Ticketing
              </span>
            </div>
            <img
              src={
                "//lvgweb.s3.us-east-2.amazonaws.com/happy/purple-ticket.svg"
              }
              className="absolute top-0"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const BasicPricing = () => {
  const { currentOrganization } = useVendor();
  const [numberOfPrices, setNumberOfPrices] = useState<number>(1);
  const { data: seasonPassGroups } = useQuery({
    queryKey: ["seasonPassGroups", currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/season_pass_groups",
        params: {
          organization_id: currentOrganization?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return [];
      });
    },
    enabled: !!currentOrganization,
    retry: false,
  });

  const { data: eventData } = useQuery({
    queryKey: ["events", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/events`,
        params: {
          organization_id: currentOrganization?.id,
          event_builder: true,
        },
      }),
    enabled: !!currentOrganization,
  });
  const events = eventData?.data;
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const { tierWizard } = eventBuilderState;
  const { priceLevels } = tierWizard;
  const { showError } = useNotification();

  const copyPriceLevelsForEvent = (event: HappyEvent) => {

    const updatedPriceLevels: BuilderPriceLevel[] = []
    event.price_levels.forEach((priceLevel: PriceLevel) => {
      const newPriceLevel = {
        name: priceLevel.name,
        price_cents: priceLevel.price_cents,
        season_pass_group_id: `${priceLevel.season_pass_group_ids[0]}`,
      };
      updatedPriceLevels.push(newPriceLevel);
      dispatch({
        type: "updateTierWizard",
        payload: { priceLevels: updatedPriceLevels },
      });
      setNumberOfPrices(updatedPriceLevels.length);
    });
  };

  const handleNumberOfPricesChange = (value: string) => {
    const currentNumberOfPrices = numberOfPrices;
    const numPrices = parseInt(value);

    if (numPrices < 1) {
      return;
    }
    setNumberOfPrices(numPrices);
    if (numPrices < currentNumberOfPrices) {
      // trim the price levels
      const updatedPriceLevels = priceLevels.slice(0, numPrices);
      dispatch({
        type: "updateTierWizard",
        payload: { priceLevels: updatedPriceLevels },
      });
    }
  };

  const handleContinueClick = () => {
    // check to see that all of the price levels have a name and price
    const valid = priceLevels.every(
      (priceLevel) => priceLevel.name && priceLevel.price_cents >= 0
    );
    if (priceLevels.length && !valid) {
      return showError("Please fill out all price levels");
    }

    dispatch({
      type: "goToStep",
      payload: { activeStep: 4 },
    });
  };

  useEffect(() => {
    if (priceLevels.length) {
      setNumberOfPrices(priceLevels.length);
    }
  }, []);

  return (
    <>
      <Menu>
        <MenuButton as="div">
          <Button variant="blue" size="sm" className="flex items-center gap-2">
            <span>Copy from past event</span>
            <DocumentDuplicateIcon className="w-4" />
          </Button>
        </MenuButton>
        <MenuItems
          className={
            "absolute bg-gray-50 shadow-lg border rounded flex flex-col divide-y divide-gray-200"
          }
        >
          {events?.map((event: HappyEvent) => {
            return (
              <MenuItem
                onClick={() => copyPriceLevelsForEvent(event)}
                as="div"
                className={
                  "p-2 hover:bg-gray-100 cursor-pointer text-sm text-gray-500"
                }
                key={event.id}
              >
                <span>{event.name}</span>
              </MenuItem>
            );
          })}
        </MenuItems>
      </Menu>
      <div className="flex items-center gap-4">
        <h3 className="text- xl font-bold text-gray-500">
          How many prices will this event have?
        </h3>

        <div className="w-20">
          <TextInput
            type="number"
            onChange={(e) => handleNumberOfPricesChange(e.target.value)}
            name="number-of-prices"
            placeholder="#"
            value={numberOfPrices}
          />
        </div>
      </div>

      <div className="flex flex-col w-full">
        {times(numberOfPrices).map((i) => {
          return (
            <BasicPriceLevelForm seasonPassGroups={seasonPassGroups} i={i} />
          );
        })}
      </div>

      <Button
        onClick={handleContinueClick}
        variant="pink"
        className="h-16 w-full mt-6 shadow-md"
      >
        Continue
      </Button>
    </>
  );
};

export const BasicPriceLevelForm = ({
  seasonPassGroups,
  i,
}: {
  seasonPassGroups: SeasonPassGroup[];
  i: number;
}) => {
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const { tierWizard } = eventBuilderState;
  const { priceLevels } = tierWizard;
  const [priceLevelName, setPriceLevelName] = useState<string>("");
  const [priceLevelPrice, setPriceLevelPrice] = useState<string>("");
  const [priceLevelSeasonPassGroup, setPriceLevelSeasonPassGroup] =
    useState<string>("");

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const updatedPriceLevels = priceLevels;
    const newPriceLevel = {
      name: priceLevelName,
      price_cents: parseFloat(priceLevelPrice) * 100,
      season_pass_group_id: priceLevelSeasonPassGroup,
    };

    updatedPriceLevels[i] = newPriceLevel;

    dispatch({
      type: "updateTierWizard",
      payload: { priceLevels: updatedPriceLevels },
    });
  };

  const handleSeasonPassGroupChange = (value: string) => {
    setPriceLevelSeasonPassGroup(value);
  };

  // handle Initial Price Level Values
  useEffect(() => {
    if (priceLevels[i]) {
      setPriceLevelName(priceLevels[i].name);
      setPriceLevelPrice(`${priceLevels[i].price_cents / 100}`);
      setPriceLevelSeasonPassGroup(priceLevels[i].season_pass_group_id || "");
    }
  }, []);

  return (
    <div key={i} className="flex items-center gap-2 w-full">
      <div className="w-2/3">
        <TextInput
          value={priceLevelName}
          onBlur={handleBlur}
          onChange={(e) => setPriceLevelName(e.target.value)}
          name={`price-${i}`}
          placeholder="Price Name"
        />
      </div>
      <div className="w-1/3">
        <TextInput
          value={priceLevelPrice}
          onBlur={handleBlur}
          name={`price-${i}-amount`}
          onChange={(e) => setPriceLevelPrice(e.target.value)}
          placeholder="$ Amount"
        />
      </div>
      {seasonPassGroups && seasonPassGroups.length ? (
        <select
          value={priceLevelSeasonPassGroup}
          onBlur={handleBlur}
          onChange={(e) => handleSeasonPassGroupChange(e.target.value)}
          name={`price-${i}-season-pass-group`}
          className="border border-gray-300 rounded p-2 w-44 focus:border-purple focus:ring-purple"
        >
          <option value="">No Pass Group</option>
          {seasonPassGroups.map((group: SeasonPassGroup) => {
            return (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            );
          })}
        </select>
      ) : null}
    </div>
  );
};

export const BasicTickets = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { currentOrganization } = useVendor();
  const [numberOfTickets, setNumberOfTickets] = useState<string>("");
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const { tierWizard } = eventBuilderState;
  const { priceLevels } = tierWizard;
  const { showSuccess, showError } = useNotification();
  const navigate = useNavigate();

  const createEvent = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { name, start, end, primaryVenue, image } = eventBuilderState;
    const formData = new FormData();
    formData.append("event_builder[ticket_amount]", numberOfTickets);
    formData.append("event_builder[event_details][name]", name);
    if (start) {
      formData.append(
        "event_builder[event_details][start_date]",
        start.toString()
      );
    }

    if (end) {
      formData.append("event_builder[event_details][end_date]", end.toString());
    }
    if (primaryVenue) {
      formData.append(
        "event_builder[event_details][primary_venue_id]",
        primaryVenue.id.toString()
      );
    }
    const priceLevelsString = JSON.stringify(priceLevels);
    formData.append("event_builder[price_levels]", priceLevelsString);
    if (image.blob) {
      formData.append("event_builder[logo]", image.blob);
    }

    makeApiRequest({
      path: `/vendor/events/builder/basic?organization_id=${currentOrganization?.id}`,
      method: "POST",
      hasFormData: true,
      params: formData,
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Your event has been created!");
          navigate(
            `/vendor/organizations/${currentOrganization?.id}/events/${res.data.id}`
          );
          localStorage.clear();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <h3 className="text-2xl font-bold text-gray-500">
        How many tickets would you like to release?
      </h3>
      <span className="text-sm">You can always add more tickets later.</span>
      <TextInput
        onChange={(e) => setNumberOfTickets(e.target.value)}
        placeholder="# of Tickets"
        name="tickets"
        value={numberOfTickets}
      />

      <Button
        disabled={loading}
        onClick={createEvent}
        variant="pink"
        className="h-16 w-full mt-6 shadow-md"
      >
        {loading ? <Spinner /> : "Finish"}
      </Button>
    </>
  );
};

export const AdvancedBuilder = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showSuccess } = useNotification();
  const navigate = useNavigate();
  const { currentOrganization } = useVendor();
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const setActiveStep = (step: number) => {
    dispatch({ type: "goToStep", payload: { activeStep: step } });
  };
  const editTier = (tierIndex: number) => {
    dispatch({
      type: "editTier",
      payload: {
        editingTierIndex: tierIndex,
      },
    });
  };

  const removePriceLevel = (
    tierIndex: number,
    sectionIndex: number,
    priceLevelIndex: number
  ) => {
    const tiers = eventBuilderState.tiers.map((tier, ti) => {
      if (ti === tierIndex) {
        const sections = tier.sections.map((section, si) => {
          if (si === sectionIndex) {
            const price_levels = section.price_levels.filter(
              (priceLevel, pi) => pi !== priceLevelIndex
            );
            return { ...section, price_levels };
          }
          return section;
        });
        return { ...tier, sections };
      }
      return tier;
    });
    dispatch({
      type: "updateEvent",
      payload: { tiers },
    });
  };

  const removeTier = (tierIndex: number) => {
    const tiers = eventBuilderState.tiers.filter(
      (tier, index) => index !== tierIndex
    );
    dispatch({
      type: "updateEvent",
      payload: { tiers, editingTierIndex: undefined },
    });
  };

  const createEvent = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { name, start, end, primaryVenue, tiers, image } = eventBuilderState;
    const formData = new FormData();
    formData.append("event_builder[event_details][name]", name);
    if (start) {
      formData.append(
        "event_builder[event_details][start_date]",
        start.toString()
      );
    }

    if (end) {
      formData.append("event_builder[event_details][end_date]", end.toString());
    }
    if (primaryVenue) {
      formData.append(
        "event_builder[event_details][primary_venue_id]",
        primaryVenue.id.toString()
      );
    }
    const tiersString = JSON.stringify(tiers);
    formData.append("event_builder[tiers]", tiersString);
    if (image.blob) {
      formData.append("event_builder[logo]", image.blob);
    }

    makeApiRequest({
      path: `/vendor/events/builder?organization_id=${currentOrganization?.id}`,
      method: "POST",
      hasFormData: true,
      params: formData,
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Your event has been created, now add some tickets!");
          navigate(
            `/vendor/organizations/${currentOrganization?.id}/events/${res.data.id}/tickets`
          );
          localStorage.clear();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const backgroundImage = eventBuilderState.image?.image?.toString();
  const valid =
    eventBuilderState.name && eventBuilderState.start && eventBuilderState.end;
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex w-1/2 items-center gap-4">
          <Button
            onClick={() => setActiveStep(1)}
            variant={eventBuilderState.activeStep === 1 ? "purple" : "default"}
            size="sm"
          >
            Event Details
          </Button>
          <div className="border-t border-gray-300 flex-1"></div>
          <Button
            disabled={!valid}
            onClick={() => setActiveStep(2)}
            variant={eventBuilderState.activeStep === 2 ? "purple" : "default"}
            size="sm"
          >
            Tickets & Pricing
          </Button>
          <div className="border-t border-gray-300 flex-1"></div>
          <Button
            disabled={!valid}
            onClick={() => setActiveStep(3)}
            variant={eventBuilderState.activeStep === 3 ? "purple" : "default"}
            size="sm"
          >
            Finish
          </Button>
        </div>
        <div className="w-1/2 flex justify-center">
          {eventBuilderState.activeStep === 2 ? (
            <h3 className="block w-3/4 text-2xl font-bold">
              Tiers for {eventBuilderState.name}
            </h3>
          ) : null}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-1/2">
          <VendorCard className="mt-10">
            {eventBuilderState.activeStep === 1 && (
              <>
                <EventName />
                <EventDate />
                <div className="w-full border-b border-gray-300 my-6" />
                <VenueSelect />
                <EventPhotoUpload />
                <Button
                  disabled={!valid}
                  onClick={() => setActiveStep(2)}
                  className="h-16 w-full mt-8"
                  variant="pink"
                >
                  Continue
                </Button>
              </>
            )}
            {eventBuilderState.activeStep === 2 && <TicketsAndPricing />}

            {eventBuilderState.activeStep === 3 && <EventSummary />}
          </VendorCard>

          {eventBuilderState.activeStep === 3 && (
            <Button
              className="mt-10 h-16 w-full flex gap-2"
              variant="pink"
              onClick={createEvent}
            >
              {loading ? (
                <Spinner />
              ) : (
                <img
                  className="h-6 w-auto"
                  src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
                  alt="Your Company"
                />
              )}
              Create Event
            </Button>
          )}
        </div>

        <div className="w-1/2 flex justify-center relative mt-10">
          {eventBuilderState.activeStep === 2 ? (
            <div className="w-3/4 flex flex-col gap-4">
              {eventBuilderState.tiers?.map((tier, tierIndex) => {
                return (
                  <VendorCard
                    className={
                      eventBuilderState.editingTierIndex === tierIndex
                        ? "ring-2 ring-blue"
                        : ""
                    }
                  >
                    <h4 className="font-bold text-lg text-gray-500 flex justify-between">
                      <span>{tier.name}</span>
                      <div className="flex gap-2">
                        <span role="button">
                          <PencilIcon
                            onClick={() => editTier(tierIndex)}
                            className="w-4"
                          />
                        </span>

                        <span
                          role="button"
                          onClick={() => removeTier(tierIndex)}
                          className="text-red-500 hover:text-red-600 "
                        >
                          <TrashIcon className="w-4" />
                        </span>
                      </div>
                    </h4>

                    <h5 className="font-bold text-sm mt-4">Sections</h5>
                    {tier.sections?.map((section, sectionIndex) => {
                      return (
                        <>
                          <span className="text-sm block my-2">
                            {section.name}
                          </span>
                          <div className="flex gap-2">
                            {section.price_levels?.map(
                              (priceLevel, priceLevelIndex) => {
                                return (
                                  <div className="relative py-3 px-6 grow bg-purple rounded-lg text-white flex flex-col items-center justify-center text-xs">
                                    <span className="font-bold">
                                      {priceLevel.name}
                                    </span>
                                    <span>
                                      {formatCentsToDollars(
                                        priceLevel.price_cents || 0
                                      )}
                                    </span>
                                    <span
                                      onClick={() =>
                                        removePriceLevel(
                                          tierIndex,
                                          sectionIndex,
                                          priceLevelIndex
                                        )
                                      }
                                      role="button"
                                      className="absolute top-1 right-1"
                                    >
                                      <XMarkIcon className="w-4 text-white" />
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      );
                    })}
                  </VendorCard>
                );
              })}
              {eventBuilderState.tiers?.length ? (
                <Button
                  onClick={() => setActiveStep(3)}
                  variant="pink"
                  className="h-10 w-full mt-6 shadow-md"
                  size="lg"
                >
                  Review and Finish
                </Button>
              ) : null}
            </div>
          ) : (
            <div className="w-[256px] h-[628px] flex flex-col items-center sticky top-20">
              <div
                style={
                  backgroundImage
                    ? {
                        background: `url('${backgroundImage}')no-repeat center center`,
                        backgroundSize: "cover",
                      }
                    : {}
                }
                className="w-[250px] mt-0.5 h-44 bg-purple static z-30 flex items-center justify-center"
              >
                {!backgroundImage && (
                  <img
                    className="w-24 h-24"
                    src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
                  />
                )}
              </div>
              <div className="p-4 static z-30 flex flex-col w-full">
                <h3 className="font-bold text-xl text-gray-500">
                  {eventBuilderState.name || "Your Event Name"}
                </h3>

                <div className="flex flex-col gap-2 mt-5 text-gray-500">
                  <div className="flex flex-col">
                    <span>
                      <b>Starts:</b>
                    </span>
                    {eventBuilderState.start && (
                      <span>
                        {format(
                          new Date(eventBuilderState.start || ""),
                          "E, MMM do @ h:mm a"
                        )}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <span>
                      <b>Ends:</b>
                    </span>
                    {eventBuilderState.end && (
                      <span>
                        {format(
                          new Date(eventBuilderState.end || ""),
                          "E, MMM do @ h:mm a"
                        )}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <span>
                      <b>Venue:</b>
                    </span>
                    <span>
                      {eventBuilderState.primaryVenue?.name || "Your Venue"}
                    </span>
                  </div>
                </div>
                <span className="font-milkshake text-lightPurple w-full text-center absolute bottom-1/4 pb-2 left-0 right-0">
                  Happy Ticketing
                </span>
              </div>
              <img
                src={
                  "//lvgweb.s3.us-east-2.amazonaws.com/happy/purple-ticket.svg"
                }
                className="absolute top-0"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const EventName = () => {
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  return (
    <>
      <h3 className="mb-2 text-2xl text-gray-500 font-bold">
        What is your event called?
      </h3>

      <div className="sm:w-full flex flex-col justify-center items-center mt-5">
        <input
          value={eventBuilderState.name}
          onChange={(e) =>
            dispatch({ type: "updateEvent", payload: { name: e.target.value } })
          }
          type="text"
          placeholder="The Best Event of the Year"
          className="focus:ring-purple focus:border-purple text-lg border border-gray-300 rounded-lg w-full p-4 h-16 placeholder:text-gray-400"
        />

        <textarea
          rows={3}
          value={eventBuilderState.description}
          onChange={(e) =>
            dispatch({
              type: "updateEvent",
              payload: { description: e.target.value },
            })
          }
          placeholder="Tell us about your event..."
          className="resize-none focus:ring-purple focus:border-purple mt-3 text-lg border border-gray-300 rounded-lg w-full p-4 placeholder:text-gray-400"
        />
      </div>
    </>
  );
};

export const EventDate = () => {
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const handleEventStartChange = (date: Date | null) => {
    if (!date) return;
    let payload: { start: string; end?: string } = {
      start: date.toISOString(),
    };
    if (!eventBuilderState.end) {
      payload = { ...payload, end: date.toISOString() };
    }
    dispatch({ type: "updateEvent", payload });
  };

  const handleEventEndChange = (date: Date | null) => {
    if (!date) return;
    dispatch({ type: "updateEvent", payload: { end: date.toISOString() } });
  };

  return (
    <>
      <h3 className="mt-10 mb-2 text-2xl text-gray-500 font-bold">
        When is your event?
      </h3>
      <div className="flex flex-col items-end gap-6">
        <div className="w-full">
          <h3 className="font-bold text-gray-500 my-2">Starts</h3>
          <div className="w-full flex items-center">
            <DatePicker
              wrapperClassName="w-full"
              dateFormat={"E, MMM do @ h:mm a"}
              selected={
                eventBuilderState.start
                  ? new Date(eventBuilderState.start)
                  : null
              }
              placeholderText="Start Date and Time"
              className="text-lg border border-gray-300 rounded-lg w-full p-4 h-16 placeholder:text-gray-400 focus:ring-purple focus:border-purple"
              timeIntervals={15}
              timeCaption="time"
              showTimeSelect
              onChange={(e) => handleEventStartChange(e)}
            />
          </div>
        </div>

        <div className="w-full">
          <h3 className="font-bold text-gray-500 my-2">Ends</h3>
          <div className="w-full flex items-center">
            <DatePicker
              wrapperClassName="w-full"
              selected={
                eventBuilderState.end ? new Date(eventBuilderState.end) : null
              }
              dateFormat={"E, MMM do @ h:mm a"}
              placeholderText="End Date and Time"
              className="text-lg border border-gray-300 rounded-lg w-full p-4 h-16 placeholder:text-gray-400 focus:ring-purple focus:border-purple"
              timeIntervals={15}
              timeCaption="time"
              showTimeSelect
              onChange={(e) => handleEventEndChange(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const VenueSelect = () => {
  const [viewAddVenueForm, setViewAddVenueForm] = useState<boolean>(false);
  const [venueAddError, setVenueAddError] = useState<string>("");
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const { currentOrganization } = useVendor();
  const [venueName, setVenueName] = useState<string>("");
  const { data, refetch: refetchVenues } = useQuery({
    queryKey: ["venues", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/venues",
        params: { organization_id: currentOrganization?.id },
      }),
    enabled: !!currentOrganization,
  });
  const venues: Venue[] = data?.data;
  const {
    data: venueSearchResults,
    refetch,
    isFetched,
  } = useQuery({
    queryKey: ["venue_search", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/venue_search",
        params: { organization_id: currentOrganization?.id, name: venueName },
      }),
    enabled: false,
  });
  const venueResults = venueSearchResults?.data;

  const removeVenue = () => {
    dispatch({
      type: "updateEvent",
      payload: { primaryVenue: undefined },
    });
  };

  const addVenue = (venue: Venue) => {
    dispatch({
      type: "updateEvent",
      payload: { primaryVenue: venue },
    });
  };

  const handleSuccessfulVenueAdd = (venue: Venue) => {
    addVenue(venue);
    setViewAddVenueForm(false);
    refetchVenues();
  };

  const allVenues: Venue[] = uniqBy(venues?.concat(venueResults || []), "id");
  if (viewAddVenueForm) {
    return (
      <div className="w-full">
        <div className="flex justify-between items-center">
          <h3 className="text-2xl font-bold text-gray-500 mb-4">
            Create a Venue
          </h3>
          <span
            onClick={() => setViewAddVenueForm(false)}
            className="text-red-500 text-sm"
            role="button"
          >
            Cancel
          </span>
        </div>
        <NewVenueForm
          initialName={venueName}
          onSuccessfulAdd={handleSuccessfulVenueAdd}
        />
      </div>
    );
  }
  return (
    <div className="w-full">
      <h3 className="text-2xl font-bold text-gray-500 mb-4">Select a Venue</h3>
      {!venues?.length ? (
        <p>
          It looks like you have no venues attached to your org. Use the search
          below to add a venue, or skip and add a venue later.
        </p>
      ) : (
        <p>
          Select one of your organization's attached venues or search for
          another venue.
        </p>
      )}
      <div className="flex gap-2 items-center my-6">
        <input
          className="focus:ring-purple focus:border-purple text-lg border border-gray-300 rounded-lg w-full p-4 h-16 placeholder:text-gray-400"
          placeholder="Search Venues"
          type="text"
          value={venueName}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              refetch();
            }
          }}
          onChange={(e) => setVenueName(e.target.value)}
        />
        <Button
          className="h-16"
          onClick={() => refetch()}
          size="lg"
          variant="purple"
        >
          Search
        </Button>
      </div>
      {isFetched && !venueResults.length && (
        <span className="italic text-sm">
          No venues match your search. Try another search or{" "}
          <span
            onClick={() => setViewAddVenueForm(true)}
            role="button"
            className="text-purple"
          >
            create a new venue
          </span>{" "}
          yourself.
        </span>
      )}

      {isFetched && venueResults.length ? (
        <span className="italic text-sm">
          Don't see your venue? Try another search or{" "}
          <span
            onClick={() => setViewAddVenueForm(true)}
            role="button"
            className="text-purple"
          >
            create a new venue
          </span>{" "}
          yourself.
        </span>
      ) : null}
      {allVenues && allVenues.length > 0 && (
        <div className="mt-4">
          {allVenues.map((venue: Venue) => {
            const venueSelected: boolean =
              venue.id === eventBuilderState.primaryVenue?.id;
            return (
              <div
                key={venue.id}
                className="py-4 px-2 border-b border-gray-300 flex justify-between items-center"
              >
                <div>
                  <h4 className="font-bold">{venue.name}</h4>
                  <span className="text-gray-400">
                    {venue.city}, {venue.state}
                  </span>
                </div>

                <Button
                  onClick={() =>
                    venueSelected ? removeVenue() : addVenue(venue)
                  }
                  variant={venueSelected ? "success" : "default"}
                  size="sm"
                  className="w-20"
                >
                  {venueSelected ? (
                    <CheckCircleIcon className="w-6 text-white" />
                  ) : (
                    "Add"
                  )}
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const EventPhotoUpload = () => {
  const { state: eventBuilderState, dispatch } = useEventBuilder();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = reader.result;
        const blob: Blob = dataURLtoBlob(image as string);
        dispatch({
          type: "updateEvent",
          payload: { image: { file: file.name, image, blob } },
        });
        // setSelectedImage(reader.result);
        // setSelectedImageName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };
  const imageFile = eventBuilderState.image?.file;

  return (
    <div className="w-full mt-5">
      <h3 className="text-2xl font-bold text-gray-500 mb-4">Upload a Photo</h3>

      <p>{imageFile ? imageFile : "No file Selected"}</p>
      <div className="my-5">
        <input
          style={{ display: "none" }}
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        />
        <label
          htmlFor="file-upload"
          className="hover:brightness-125 cursor-pointer px-10 h-16 items-center flex gap-2 justify-center bg-purple text-white rounded-lg"
        >
          <CameraIcon className="w-5 text-white" /> Upload a Photo
        </label>
      </div>
    </div>
  );
};

export const TicketsAndPricing = () => {
  const [showTierForm, setShowTierForm] = useState<boolean>(false);
  const { state: eventBuilderState, dispatch } = useEventBuilder();

  const { editingTierIndex } = eventBuilderState;

  const cancelEdit = () => {
    dispatch({
      type: "cancelEdit",
      payload: {},
    });
  };

  useEffect(() => {
    if (editingTierIndex !== undefined) {
      setShowTierForm(true);
      dispatch({
        type: "updateTierWizard",
        payload: {
          newSectionName: "",
        },
      });
    }
  }, [editingTierIndex]);

  return (
    <>
      <div className="flex items-end gap-1">
        <h3 className="text-2xl font-bold text-gray-500">
          {editingTierIndex !== undefined
            ? "Editing Tier"
            : "Create Tiers & Pricing"}
        </h3>
        {editingTierIndex !== undefined ? (
          <span
            onClick={cancelEdit}
            className="text-sm text-red-500 mb-0.5"
            role="button"
          >
            Cancel
          </span>
        ) : null}
      </div>
      {showTierForm ? null : (
        <p className="my-3">
          Create tiers and sections and then finally price levels within those
          sections. We provided one default Tier with a section and a price
          level to start you off.
        </p>
      )}
      <div className="flex flex-col gap-4 mt-3 pb-6">
        {showTierForm ? (
          <TierWizard hideTierForm={() => setShowTierForm(false)} />
        ) : (
          <button
            onClick={() => setShowTierForm(true)}
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue/50 focus:ring-offset-2"
          >
            <span className="mt-2 block text-sm font-semibold text-gray-500">
              Create a new tier
            </span>
          </button>
        )}
      </div>
    </>
  );
};

export const EventSummary = () => {
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  return (
    <div className="w-full flex flex-col gap-6">
      <section>
        <h3 className="text-2xl font-bold text-gray-500 mb-3">Event Details</h3>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <h4 className="font-bold">Event Name</h4>
            <span>{eventBuilderState.name}</span>
          </div>
          <div className="flex justify-between">
            <h4 className="font-bold">Starts</h4>
            <span>
              {format(
                new Date(eventBuilderState.start || ""),
                "E, MMM do @ h:mm a"
              )}
            </span>
          </div>
          <div className="flex justify-between">
            <h4 className="font-bold">Ends</h4>
            <span>
              {format(
                new Date(eventBuilderState.end || ""),
                "E, MMM do @ h:mm a"
              )}
            </span>
          </div>
          <div className="flex justify-between">
            <h4 className="font-bold">Venue</h4>
            <span>{eventBuilderState.primaryVenue?.name || "Your Venue"}</span>
          </div>
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-bold text-gray-500 mb-3">
          Tiers & Pricing
        </h3>
        <div className="flex flex-col gap-3">
          {eventBuilderState.tiers?.map((tier, tierIndex) => {
            return (
              <div>
                <h4 className="font-bold text-lg">{tier.name}</h4>
                <ul>
                  {tier.sections?.map((section, sectionIndex) => {
                    return (
                      <li className="flex gap-2 items-center my-1">
                        <span>
                          <UserGroupIcon className="w-5" />
                        </span>
                        <span>{section.name}</span>
                        {" - "}
                        <span className="flex gap-1 text-center">
                          {section.price_levels.map((priceLevel) => (
                            <span className="text-xs py-1 px-2 bg-pink/30 text-pink rounded-full">
                              {priceLevel.name}{" "}
                              {formatCentsToDollars(
                                priceLevel.price_cents || 0
                              )}
                            </span>
                          ))}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export const TierWizard = ({ hideTierForm }: { hideTierForm: () => void }) => {
  const { showSuccess, showError } = useNotification();
  const { state: eventBuilderState, dispatch } = useEventBuilder();
  const [showSectionsAndPriceLevels, setShowSectionsAndPriceLevels] =
    useState<boolean>(false);
  const { editingTierIndex, editingSectionIndex, tierWizard } =
    eventBuilderState;
  const {
    newTierName,
    newSectionName,
    newPriceLevelName,
    newPriceLevelPrice,
    sections,
    priceLevels,
  } = tierWizard;

  const createSection = () => {
    const builderSection: BuilderSection = {
      name: newSectionName,
      price_levels: priceLevels,
    };
    dispatch({
      type: "updateTierWizard",
      payload: {
        sections: [...sections, builderSection],
        priceLevels: [],
        newSectionName: "",
        newPriceLevelName: "",
        newPriceLevelPrice: null,
      },
    });
  };

  const removePriceLevel = (index: number) => {
    const newPriceLevels = priceLevels.filter((priceLevel, i) => i !== index);
    dispatch({
      type: "updateTierWizard",
      payload: { priceLevels: newPriceLevels },
    });
    // setPriceLevels(newPriceLevels);
  };

  const createPriceLevel = () => {
    const builderPriceLevel: BuilderPriceLevel = {
      name: newPriceLevelName,
      price_cents: newPriceLevelPrice ? newPriceLevelPrice * 100 : 0,
    };
    dispatch({
      type: "updateTierWizard",
      payload: {
        priceLevels: [...priceLevels, builderPriceLevel],
        newPriceLevelName: "",
        newPriceLevelPrice: null,
      },
    });
  };

  const updateTier = () => {
    const tier = {
      name: newTierName,
      sections: sections,
    };
    const tiers = eventBuilderState.tiers.map((t, index) =>
      index === editingTierIndex ? tier : t
    );

    dispatch({
      type: "updateEvent",
      payload: { tiers, editingTierIndex: undefined },
    });
    showSuccess("Tier Updated.");
    hideTierForm();

    dispatch({
      type: "updateTierWizard",
      payload: {
        newTierName: "",
        sections: [],
        priceLevels: [],
      },
    });
  };

  const removeSection = (index: number) => {
    const newSections = sections.filter((section, i) => i !== index);
    dispatch({
      type: "updateTierWizard",
      payload: { sections: newSections },
    });
    // setSections(newSections);
  };

  const createTier = () => {
    const tier = {
      name: newTierName,
      sections: sections,
    };
    dispatch({
      type: "updateEvent",
      payload: { tiers: [...eventBuilderState.tiers, tier] },
    });
    hideTierForm();
    dispatch({
      type: "updateTierWizard",
      payload: {
        newTierName: "",
        sections: [],
        priceLevels: [],
      },
    });
  };

  const editSection = (section: BuilderSection) => {
    dispatch({
      type: "updateTierWizard",
      payload: {
        newSectionName: section.name,
        priceLevels: section.price_levels,
      },
    });
    dispatch({
      type: "editSection",
      payload: {
        editingSectionIndex: sections.findIndex((s) => s === section),
      },
    });
  };

  const updateSection = () => {
    const section = {
      name: newSectionName,
      price_levels: priceLevels,
    };
    if (
      typeof editingSectionIndex === "number" &&
      typeof editingTierIndex === "number"
    ) {
      const sections = eventBuilderState.tiers[editingTierIndex].sections.map(
        (s, index) => (index === editingSectionIndex ? section : s)
      );

      dispatch({
        type: "updateEvent",
        payload: {
          editingSectionIndex: undefined,
        },
      });

      // display new sections in tier edit section
      dispatch({
        type: "updateTierWizard",
        payload: {
          sections,
          newSectionName: "",
          priceLevels: [],
        },
      });
    }

    return;
  };

  useEffect(() => {
    if (editingTierIndex !== undefined) {
      const tier = eventBuilderState.tiers[editingTierIndex];
      if (tier) {
        dispatch({
          type: "updateTierWizard",
          payload: {
            newTierName: tier.name,
            sections: tier.sections,
          },
        });
      }
    }
  }, [editingTierIndex]);

  return (
    <>
      {!showSectionsAndPriceLevels ? (
        <section>
          {editingTierIndex === undefined ? (
            <>
              <h4 className="font-bold text-lg">Event Tiers</h4>
              <p className="my-2">
                Event tiers are the top level when thinking about your tickets.
              </p>
            </>
          ) : null}

          {/*********** STEP 1 ****************/}
          <label className="font-semibold block my-2">Tier Name</label>
          <TierWizardInput
            payloadType="newTierName"
            className="text-lg border border-gray-300 rounded-lg w-full p-4 h-16 placeholder:text-gray-400"
            type="text"
            placeholder="Tier Name (e.g. General Admission, Early Bird, etc.)"
          />

          <Button
            disabled={!newTierName}
            onClick={() => setShowSectionsAndPriceLevels(true)}
            className="mt-5"
            variant="blue"
          >
            Next: Sections & Price Levels <ArrowRightIcon className="w-5" />
          </Button>
        </section>
      ) : (
        <span
          className="flex items-center font-bold text-blue"
          onClick={() => setShowSectionsAndPriceLevels(false)}
          role="button"
        >
          {newTierName}
          <PencilIcon className="w-4" />
        </span>
      )}
      {/* END STEP 1 **********************/}

      {/************** STEP 2 *****************/}
      {showSectionsAndPriceLevels ? (
        <section>
          <h4 className="font-bold text-lg">Sections & Price Levels</h4>
          <p className="my-2">
            Sections are a sublevel of tier and allow for more flexibility for
            tickets. Price levels are associated with the section and describe
            the actual cost of the ticket.
          </p>

          <ul className="my-5">
            {sections?.map((section, index) => {
              return (
                <li className="flex gap-2 items-center">
                  <span>
                    <UserGroupIcon className="w-5" />
                  </span>
                  <span>{section.name}</span>
                  {" - "}
                  <span>{section.price_levels.length} price levels</span>
                  <span
                    onClick={() => removeSection(index)}
                    className="text-red-500 hover:text-red-600 cursor-pointer"
                  >
                    <XMarkIcon className="w-5" />
                  </span>
                  <span role="button" onClick={() => editSection(section)}>
                    <PencilIcon className="w-5" />
                  </span>
                </li>
              );
            })}
          </ul>

          <label className="font-semibold block my-2">
            {editingSectionIndex !== undefined
              ? "Edit Section Name"
              : "Section Name"}
          </label>
          <TierWizardInput
            payloadType="newSectionName"
            className="text-lg border border-gray-300 rounded-lg w-full p-4 h-16 placeholder:text-gray-400"
            type="text"
            placeholder="Section (e.g. Friday, Saturday, etc.)"
          />
        </section>
      ) : null}

      {newSectionName ? (
        <section>
          <label className="font-semibold block my-2">
            Price Levels for {newSectionName}
          </label>
          <ul>
            {priceLevels.map((priceLevel, index) => {
              return (
                <li className="flex gap-2 items-center">
                  <span>
                    <TicketIcon className="w-5" />
                  </span>
                  <span>{priceLevel.name}</span>
                  {" - "}
                  <span>
                    {formatCentsToDollars(priceLevel.price_cents || 0)}
                  </span>
                  <span
                    onClick={() => removePriceLevel(index)}
                    className="text-red-500 hover:text-red-600 cursor-pointer"
                  >
                    <XMarkIcon className="w-5" />
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="flex gap-3 mt-3">
            <TierWizardInput
              payloadType="newPriceLevelName"
              type="text"
              className="w-2/3 placeholder:text-gray-400 focus:border-gray-300 focus:ring-0 border-b border-t-0 border-l-0 border-r-0 border-gray-300"
              placeholder="Name (e.g. Adult, Child, Military, etc.)"
            />
            <div className="relative w-1/3">
              <CurrencyDollarIcon className="w-5 text-gray-500 absolute left-2 top-0 bottom-0 m-auto" />
              <input
                value={`${newPriceLevelPrice}`}
                onChange={(e) =>
                  dispatch({
                    type: "updateTierWizard",
                    payload: { newPriceLevelPrice: parseFloat(e.target.value) },
                  })
                }
                step={0.01}
                type="number"
                className="w-full placeholder:text-gray-400 focus:border-gray-300 focus:ring-0 pl-8 border-b border-t-0 border-l-0 border-r-0 border-gray-300"
                placeholder="Price"
              />
            </div>

            <button
              onClick={createPriceLevel}
              className={`${
                !newPriceLevelName || !newPriceLevelPrice ? "opacity-0" : ""
              } hover:bg-green-500/10 text-green-500 px-3 rounded-lg flex items-center justify-center gap-1 font-bold grow hover:brightness-110`}
            >
              <span>Add</span>
            </button>
          </div>

          <div className="flex my-10">
            {priceLevels.length > 0 ? (
              <Button
                className="w-full"
                onClick={
                  editingSectionIndex !== undefined
                    ? updateSection
                    : createSection
                }
                variant="blue"
                size="lg"
              >
                {editingSectionIndex ? "Update Section" : "Create Section"}
              </Button>
            ) : null}
          </div>
        </section>
      ) : null}

      <section className="w-full">
        {sections?.length > 0 ? (
          <Button
            onClick={editingTierIndex !== undefined ? updateTier : createTier}
            variant="success"
            className="h-16 w-full flex items-center gap-2"
          >
            {editingTierIndex !== undefined ? (
              <>
                <CloudArrowUpIcon className="w-6 text-white" />
                Update Tier
              </>
            ) : (
              <>
                <PlusIcon className="w-6 text-white" />
                Finish and Add Tier
              </>
            )}
          </Button>
        ) : null}
      </section>
    </>
  );
};
