import { useQuery } from "@tanstack/react-query";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { useState } from "react";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { OrdersTable } from "../Events/EventDetails";
import { ListBulletIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/reusable/Form";
type SearchFormQuery = {
  id: string;
  eventName: string;
  from: Date | null;
  to: Date | null;
};
export const OrderList = () => {
  const { currentOrganization } = useVendor();
  const [orderId, setOrderId] = useState<string>("");
  const [eventName, setEventName] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<SearchFormQuery>();
  const { data: ordersData, refetch } = useQuery({
    queryKey: ["orders", searchQuery],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/orders`,
        params: {
          organization_id: currentOrganization?.id,
          search: searchQuery,
        },
      });
    },
    enabled: !!currentOrganization,
  });

  const handleClearSearch = () => {
    setError("");
    setOrderId("");
    setEventName("");
    setDateFrom(null);
    setDateTo(null);
    setSearchQuery(undefined);
  };

  const handleSubmit = () => {
    setError("");
    const payload: SearchFormQuery = {
      id: orderId,
      eventName: eventName,
      to: dateTo,
      from: dateFrom,
    };
    if (isEqual(payload, searchQuery)) {
      refetch();
      return;
    }
    setSearchQuery(payload);
  };

  const orders = ordersData?.data;
  
  return (
    <>
      <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
        <ListBulletIcon className="h-7 w-7" />
        Orders
      </h1>
      <VendorCard className="mt-4 mb-5 py-6 flex justify-between items-center">
        <div className="flex items-center relative justify-between gap-3">
          <TextInput
            name="orderId"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            placeholder="Order ID"
            className="w-1/4"
          />
          <TextInput
            name="eventName"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            placeholder="Event Name"
            className="w-1/4"
          />
          <div className="relative">
            <label className="text-xs absolute -top-5 text-gray-500 ml-3">
              From
            </label>
            <DatePicker
              placeholderText="Start Date"
              wrapperClassName="w-full"
              selected={dateFrom}
              onChange={(date) => setDateFrom(date as Date)}
              className="h-10 block rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
            />
          </div>
          <div className="relative">
            <label className="text-xs absolute -top-5 text-gray-500 ml-3">
              To
            </label>
            <DatePicker
              placeholderText="End Date"
              wrapperClassName="w-full"
              selected={dateTo}
              onChange={(date) => setDateTo(date as Date)}
              className="h-10 block rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
            />
          </div>
          <button
            className="h-10 bg-purple rounded-lg px-10 text-white"
            onClick={handleSubmit}
          >
            Search
          </button>
          {orderId || eventName || dateFrom || dateTo ? (
            <button
              className="text-pink hover:brightness-125 absolute left-0 text-xs -bottom-2"
              onClick={handleClearSearch}
            >
              Clear Search
            </button>
          ) : null}
          <span className="text-yellow absolute left-0 text-xs -bottom-6">
            {error}
          </span>
        </div>
      </VendorCard>
      {orders && orders.length > 0 ? (
        <>
          <VendorCard>
            <OrdersTable orders={orders} />
          </VendorCard>
        </>
      ) : (
        <VendorCard>
          <span></span>
        </VendorCard>
      )}
    </>
  );
};
