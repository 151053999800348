import { useQuery } from "@tanstack/react-query";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import {
  formatCentsToDollars,
  formatCentsToReadableString,
} from "../../../utils/currency";
import { ArrowUpIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../utils/styles";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { Order, PriceInfo } from "../../../types/Order";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { HappyEvent } from "../../../types/Event";
import { User } from "../../../types/User";
import { VendorCard } from "../../../components/reusable/VendorCard";
import {EventTemplateSelect} from "../EventQuickStart/EventTemplateSelect";
import { Button } from "../../../components/reusable/Button";
import {
  EllipsisHorizontalCircleIcon,
  EyeIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { Field, FieldInputProps, Form, Formik } from "formik";
import { SelectInput, TextInput } from "../../../components/reusable/Form";
import { timezoneOptions } from "../../../utils/timezones";
import { useNotification } from "../../../providers/NotificationContext";
import { Menu } from "@headlessui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type RevenueByDay = {
  day: string;
  revenue: number;
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "bottom" as const,
      onClick: function (event: unknown, legendItem: unknown, legend: unknown) {
        console.log(`You clicked on ${legend}`);
        // Perform your action here...
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: unknown) {
          return "$" + value;
        },
      },
    },
  },
};

type DashboardData = {
  event_id: number;
  event_name: string;
  total_revenue_cents: number;
  percentage_difference_in_total_orders: string;
  percentage_difference_in_tickets_sold: string;
  percentage_difference_in_total_revenue: string;
  start_date: string;
  end_date: string;
  total_balance_this_month: number;
  total_base_price_all_time: number;
  total_happy_fees_this_month: number;
  total_payouts_all_time: number;
  total_processing_fees_this_month: number;
  total_taxes_all_time: number;
};

export const Dashboard = () => {
  const { showSuccess, showError } = useNotification();
  const { currentOrganization, refetchOrganization } = useVendor();
  const [editingOrganization, setEditingOrganization] = useState(false);
  const { data: recentOrderData } = useQuery({
    queryKey: ["recent_orders", currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/orders`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!currentOrganization,
    retry: false,
  });
  const recentOrders = recentOrderData?.data || [];

  const { data: dashboardData } = useQuery({
    queryKey: ["dashboard", currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/dashboard`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!currentOrganization,
    retry: false,
  });

  const total_revenue_this_month =
    dashboardData?.data.total_revenue_this_month || 0;
  const total_orders_this_month =
    dashboardData?.data.total_orders_this_month || 0;
  const tickets_sold_this_month =
    dashboardData?.data.tickets_sold_this_month || 0;
  const total_revenue_by_day = dashboardData?.data.total_revenue_by_day || [];
  const labels = total_revenue_by_day.map((item: RevenueByDay) => item.day);
  console.log(dashboardData)
  const chartData = {
    labels,
    datasets: [
      {
        fill: {
          target: "origin",
          above: "rgba(76, 201, 240, 0.45)", // And blue below the origin
        },
        label: "Revenue",
        data: total_revenue_by_day
          ? total_revenue_by_day.map((item: RevenueByDay) => item.revenue / 100)
          : [],
        backgroundColor: "#4cc9f0",
      },
    ],
  };
  if (!currentOrganization) {
    return null;
  }
  
  return (
    <>
      <div className="flex flex-col sm:flex-row sm:justify-between mb-10">
        <div className="flex gap-4">
          <img
            src={
              currentOrganization?.image_url ||
              "//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-black.svg"
            }
            alt="Org Image"
            className="w-28 h-auto rounded-md"
          />
          <div className="flex flex-col gap-1">
            <h1 className="text-2xl font-bold text-gray-500 flex items-center gap-2">
              {currentOrganization?.name}{" "}
            </h1>
            <span className="flex gap-2 items-center">
              <MapPinIcon className="w-5" />
              <span>
                {currentOrganization?.city}, {currentOrganization?.state}
              </span>
            </span>
          </div>
        </div>
        <div className="flex gap-2">
          <Button
            size="sm"
            onClick={() => setEditingOrganization(true)}
            variant="default"
            className="flex gap-1 items-center"
          >
            <PencilIcon className="h-4 mr-1" />
            Edit Organization
          </Button>
          <Link
            target="_blank"
            to={`${import.meta.env.VITE_CONSUMER_WEB_URL}/orgs/${
              currentOrganization?.id
            }`}
          >
            <Button className="flex gap-1" size="sm" variant="blue">
              <EyeIcon className="w-4" />
              View Public Organization Page
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-3 md:flex-row md:gap-10 w-full">
          <VendorCard className="w-full md:w-1/3">
            <div className="mx-auto flex max-w-xs flex-col gap-y-4 items-center justify-center h-full">
              <dt className="font-bold text-base leading-7 text-gray-500">
                Orders Completed
              </dt>
              <dd className="relative order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-4xl">
                {total_orders_this_month}
                <span
                  className={classNames(
                    "absolute text-sm bottom-0 ml-1 flex -right-14",
                    dashboardData?.data.percentage_difference_in_total_orders.includes(
                      "-"
                    )
                      ? "text-red-500"
                      : "text-green-500"
                  )}
                >
                  {dashboardData?.data.percentage_difference_in_total_orders.includes(
                    "-"
                  ) ? (
                    <ArrowDownIcon className="w-4" />
                  ) : (
                    <ArrowUpIcon className="w-4" />
                  )}
                  {dashboardData?.data.percentage_difference_in_total_orders.substring(
                    1
                  )}
                </span>
              </dd>
              <span className="text-gray-400 text-sm">
                {dashboardData?.data.start_date}
                {" - "}
                {dashboardData?.data.end_date}
              </span>
            </div>
          </VendorCard>
          <VendorCard className="w-full md:w-1/3">
            <div className="mx-auto flex max-w-xs flex-col gap-y-4 items-center justify-center h-full">
              <dt className="font-bold text-base leading-7 text-gray-500">
                Tickets Sold
              </dt>
              <dd className="relative order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-4xl">
                {tickets_sold_this_month}
                <span
                  className={classNames(
                    "absolute text-sm bottom-0 ml-1 flex -right-14",
                    dashboardData?.data.percentage_difference_in_tickets_sold.includes(
                      "-"
                    )
                      ? "text-red-500"
                      : "text-green-500"
                  )}
                >
                  {dashboardData?.data.percentage_difference_in_tickets_sold.includes(
                    "-"
                  ) ? (
                    <ArrowDownIcon className="w-4" />
                  ) : (
                    <ArrowUpIcon className="w-4" />
                  )}
                  {dashboardData?.data.percentage_difference_in_tickets_sold.substring(
                    1
                  )}
                </span>
              </dd>
              <span className="text-gray-400 text-sm">
                {dashboardData?.data.start_date}
                {" - "}
                {dashboardData?.data.end_date}
              </span>
            </div>
          </VendorCard>
          <VendorCard className="w-full md:w-1/3">
            <div className="mx-auto flex max-w-xs flex-col gap-y-4 items-center justify-center h-full">
              <dt className="font-bold text-base leading-7 text-gray-500">
                Net Sales
              </dt>
              <dd className="relative order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-4xl">
                {formatCentsToReadableString(total_revenue_this_month)}
                <span
                  className={classNames(
                    "absolute text-sm bottom-0 ml-1 flex -right-14",
                    dashboardData?.data.percentage_difference_in_total_revenue.includes(
                      "-"
                    )
                      ? "text-red-500"
                      : "text-green-500"
                  )}
                >
                  {dashboardData?.data.percentage_difference_in_total_revenue.includes(
                    "-"
                  ) ? (
                    <ArrowDownIcon className="w-4" />
                  ) : (
                    <ArrowUpIcon className="w-4" />
                  )}
                  {dashboardData?.data.percentage_difference_in_total_revenue.substring(
                    1
                  )}
                </span>
              </dd>
              <span className="text-gray-400 text-sm">
                {dashboardData?.data.start_date}
                {" - "}
                {dashboardData?.data.end_date}
              </span>
            </div>
          </VendorCard>
        </div>
        <VendorCard>
          <div className="flex flex-col md:flex-row gap-5">
            <div className="w-full md:w-1/2">
              <span className="text-lg block mb-4">
                Sales{" "}
                <span className="text-gray-400 text-sm">{`(${dashboardData?.data.start_date} - ${dashboardData?.data.end_date})`}</span>
              </span>
              <Line data={chartData} options={options} />
            </div>
            <div className="w-full md:w-1/2">
              <span className="text-lg">
                Earnings{" "}
                <span className="text-gray-400 text-sm">(All Time)</span>
              </span>
              <div className="flex gap-1 items-center mt-4">
                <div className="w-1/2">
                  <Donut dashboardData={dashboardData?.data} />
                </div>
                <div className="w-1/2 flex flex-col gap-2 pl-3">
                  <span className="text-sm flex gap-2 items-center">
                    <span className="block rounded-full bg-lightPurple w-3 h-3" />
                    <span className="font-bold">
                      {formatCentsToDollars(
                        dashboardData?.data.total_base_price_all_time
                      )}
                    </span>
                    <span className="text-gray-400">Net Sales</span>
                  </span>
                  {currentOrganization?.add_tax_at_checkout && (
                    <span className="text-sm flex gap-2 items-center">
                      <span className="block rounded-full bg-blue w-3 h-3" />
                      <span className="font-bold">
                        {formatCentsToDollars(
                          dashboardData?.data.total_taxes_all_time
                        )}
                      </span>
                      <span className="text-gray-400">Taxes</span>
                    </span>
                  )}
                  <span className="text-sm flex gap-2 items-center">
                    <span className="block rounded-full bg-pink w-3 h-3" />
                    <span className="font-bold">
                      {formatCentsToDollars(
                        dashboardData?.data.total_payouts_all_time
                      )}
                    </span>
                    <span className="text-gray-400">Payouts</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </VendorCard>
        <VendorCard>
          <h3 className="text-lg font-bold text-gray-500 mb-5">
            Recent Orders
          </h3>
          <RecentOrderTable orders={recentOrders} />
        </VendorCard>
      </div>

      <Modal
        onRequestClose={() => setEditingOrganization(false)}
        isOpen={editingOrganization}
        title="Edit Organization"
      >
        <>
          <Formik
            onSubmit={(data) => {
              makeApiRequest({
                path: `/vendor/organizations/${currentOrganization?.id}`,
                method: "PUT",
                params: {
                  organization_id: currentOrganization?.id,
                  organization: data,
                },
              }).then((res) => {
                if (res.status === 200) {
                  showSuccess("Organization updated successfully");
                  setEditingOrganization(false);
                  refetchOrganization();
                }
              });
            }}
            initialValues={{
              name: currentOrganization?.name,
              timezone_name: currentOrganization?.timezone_name,
              city: currentOrganization?.city,
              address_1: currentOrganization?.address_1,
              address_2: currentOrganization?.address_2,
              state: currentOrganization?.state,
              zip: currentOrganization?.zip,
              contact_phone: currentOrganization?.contact_phone,
              contact_email: currentOrganization?.contact_email,
            }}
          >
            <Form className="flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="name">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">Organization Name</label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <Field name="timezone_name">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <SelectInput
                        initialValue={{
                          value: currentOrganization?.timezone_name || "",
                          display: currentOrganization?.timezone_name || "",
                        }}
                        onSelect={(option) => {
                          field.onChange({
                            target: { name: field.name, value: option.value },
                          });
                        }}
                        label="Timezone"
                        options={timezoneOptions}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="address_1">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">Address</label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          placeholder="Address 1"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <Field name="address_2">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">
                          Address 2 (optional)
                        </label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          placeholder="State"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="city">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">City</label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <Field name="state">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">State</label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          placeholder="State"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="zip">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">Zip</label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          type="text"
                          placeholder="Zip"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <Field name="contact_phone">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">Phone Number</label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          placeholder="Phone Number"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="contact_email">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <label className="mb-2 block">Email Address</label>
                        <input
                          className="h-10 block w-full rounded bg-gray-200 text-gray-500 focus:ring-purple border-0 placeholder:text-gray-400"
                          placeholder="Email Address"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2"></div>
              </div>

              <Button type="submit" variant="blue" size="lg">
                Save Changes
              </Button>
            </Form>
          </Formik>
        </>
      </Modal>
    </>
  );
};

const Donut = ({ dashboardData }: { dashboardData?: DashboardData }) => {
  const { currentOrganization } = useVendor();
  let labels = ["Base Price", "Payouts"];
  let backgroundColors = ["#BB53FF", "#f72585"];
  let dashboardDataArray = [
    dashboardData?.total_base_price_all_time,
    dashboardData?.total_payouts_all_time,
  ];
  if (currentOrganization?.add_tax_at_checkout) {
    labels = ["Base Price", "Taxes", "Payouts"];
    dashboardDataArray = [
      dashboardData?.total_base_price_all_time,
      dashboardData?.total_taxes_all_time,
      dashboardData?.total_payouts_all_time,
    ];
    backgroundColors = ["#BB53FF", "#4361ee", "#f72585"];
  }

  const data = {
    labels,
    datasets: [
      {
        data: dashboardDataArray,
        backgroundColor: backgroundColors,
      },
    ],
  };

  return (
    <div className="relative">
      <Doughnut
        data={data}
        options={{
          responsive: true,
          cutout: 80,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
      <span className="flex flex-col justify-center items-center absolute top-0 right-0 bottom-0 left-0 m-auto z-10">
        <span className="font-bold text-lg">
          {formatCentsToDollars(dashboardData?.total_balance_this_month || 0)}
        </span>
        <span className="text-gray-400">Balance</span>
      </span>
    </div>
  );
};

const RecentOrderTable = ({ orders }: { orders: Order[] }) => {
  const columnHelper = createColumnHelper<Order>();
  const { currentOrganization } = useVendor();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => {
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${
              currentOrganization?.id
            }/orders/${info.getValue()}`}
          >
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("event", {
      header: "Event",
      cell: (info) => {
        const event: HappyEvent | undefined = info.renderValue() as
          | HappyEvent
          | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/events/${event?.id}`}
          >
            {event?.name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("user", {
      header: "User",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return (
          <span>
            {user?.first_name} {user?.last_name}
          </span>
        );
      },
    }),
    columnHelper.accessor("price_info", {
      header: "Order Total",
      cell: (info) => {
        const priceInfo: PriceInfo | undefined = info.renderValue() as
          | PriceInfo
          | undefined;
        return <span>${((priceInfo?.total || 0) / 100).toFixed(2)}</span>;
      },
    }),
    columnHelper.accessor("created_at", {
      header: "Created",
      cell: (info) => format(new Date(info.getValue()) as Date, "MM/dd/yyyy"),
    }),
  ];
  const table = useReactTable({
    data: orders,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <table className="min-w-full divide-y divide-gray-200 text-sm">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className="text-white/50" key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th className="text-left text-gray-500" key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="divide-y divide-gray-200">
        {table?.getRowModel()?.rows?.map((row) => {
          return (
            <tr className="h-11 text-gray-400" key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td className="py-1" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
