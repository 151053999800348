import { HappyEvent } from "../../../types/Event";
import { Fragment, ReactNode, useState } from "react";
import { Order, PriceInfo } from "../../../types/Order";
import { User } from "../../../types/User";
import { Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import {
  Table,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Pagination, Resources } from "../../../components/reusable/Pagination";
import {
  formatCentsToDollars,
  formatCentsToReadableString,
} from "../../../utils/currency";
import { useVendor } from "../../../providers/VendorContext";
import { ArrowDownIcon, ArrowUpIcon, ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import { makeApiRequest } from "../../../utils/api";
import { saveAs } from "file-saver";
export const EventDetails = ({
  event,
  orders,
}: {
  event: HappyEvent;
  orders: Order[];
}) => {
  const stats = [
    { id: 1, name: "Tickets Sold", value: event.ticket_info?.sold || 0 },
    {
      id: 2,
      name: "Tickets Available",
      value: event.ticket_info?.available || 0,
    },
    {
      id: 3,
      name: "Total Revenue",
      value: formatCentsToDollars(event.total_revenue) || 0,
    },
  ];
  const first10Orders = orders?.length ? orders.slice(0, 10) : []
  return (
    <div className="p-4 rounded bg-white shadow-sm mt-5">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 border-b border-gray-200 py-3 sm:py-6">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center md:grid-cols-3">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
            >
              <dt className="text-base leading-7 text-gray-500">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <h3 className="font-bold text-gray-500 mt-5">Recent Orders</h3>
      <OrdersTable showCsvDownload={false} orders={first10Orders} />
    </div>
  );
};

export const OrdersTable = ({
  orders,
  showCsvDownload = true,
}: {
  showCsvDownload?: boolean;
  orders: Order[];
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<Order>();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => {
        return (
          <Link
            className="text-lightPurple"
            to={`/vendor/organizations/${
              currentOrganization?.id
            }/orders/${info.getValue()}`}
          >
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("event", {
      header: "Event",
      cell: (info) => {
        const event: HappyEvent | undefined = info.renderValue() as
          | HappyEvent
          | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/events/${event?.id}`}
          >
            {event?.name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("customer_last_name", {
      header: "User",
      cell: (info) => {
        // return <span>{info.getValue()}</span>
        const user: User | undefined = info.row.original.user as
          | User
          | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/customers/${user?.id}`}
          >
            {user?.first_name} {user?.last_name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("total_price_cents", {
      header: "Order Total",
      cell: (info) => {
        return <span>{formatCentsToDollars(info.getValue())}</span>;
      },
    }),
    columnHelper.accessor("created_at", {
      header: "Created",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10 cursor-pointer "}
                    onClick={() =>
                      downloadOrderReceipt(props.row.original.id)
                    }
                  >
                    <button className="text-gray-500 block">
                      <ArrowDownTrayIcon className="h-5 w-5 inline mr-2" />
                      Order PDF
                    </button>
                  </Menu.Item>
                 
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const downloadOrderReceipt = (order_id: string | number) => {
    makeApiRequest({
      path: `/orders/${order_id}/download_pdf`,
      method: "GET",
      responseType: 'blob',
    }).then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `order_${order_id}_receipt.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }).catch((error) => {
      console.error('Error downloading PDF:', error);
    });
  };
  

  const table = useReactTable({
    data: orders,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  }) as Table<Resources>;

  const downloadCSV = () => {
    makeApiRequest({
      path: `/vendor/orders.csv`,
      params: {
        organization_id: currentOrganization?.id,
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `orders-${format(new Date(), "MM/dd/yyyy")}.csv`;
        saveAs(blob, filename);
      }
    });
  };

  return (
    <>
      {showCsvDownload && orders?.length ? (
        <span
          onClick={downloadCSV}
          role="button"
          className="block text-blue text-sm ml-auto text-right"
        >
          Download CSV
        </span>
      ) : null}
      {orders && orders.length ? (
        <>
          <table className="min-w-full divide-y divide-gray-200 mt-5">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className="text-gray-500 text-sm" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th className="text-left" key={header.id}>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        <span className="px-2">
                          {header.column.getIsSorted() === "asc" && (
                            <ArrowUpIcon className="h-3 w-3 inline" />
                          )}
                          {header.column.getIsSorted() === "desc" && (
                            <ArrowDownIcon className="h-3 w-3 inline" />
                          )}
                        </span>

                        {/* {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted() as string] ?? null} */}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-gray-200">
              {table?.getRowModel()?.rows?.map((row) => {
                return (
                  <tr className="h-11 text-sm" key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td className="py-1 text-gray-400" key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <p className="text-center py-3">No orders.</p>
      )}
    </>
  );
};
