import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { FormEvent, FormEventHandler, Fragment, ReactNode, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { orderBy } from "lodash";
import { Organization } from "../../../types/Organization";

export const Organizations = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState<string>("");
  const { data, refetch } = useQuery({
    queryKey: ["organizations"],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/organizations",
        params: { search: { name } },
      }),
    enabled: true,
  });

  const organizations: Organization[] = data?.data;
  const columnHelper = createColumnHelper<Organization>();

  const handleSubmit = ((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) {
      setError("Please enter a name to search");
      return;
    }
    refetch();
  });

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => (
        <Link className="text-lightPurple hover:brightness-125" to={`/admin/organizations/${info.row.original.id}`}>
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("address_1", {
      header: "Address",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("contact_phone", {
      header: "Phone",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("contact_email", {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("event_count", {
      header: "Events",
      cell: (info) => (
        <span className="block text-center">{info.getValue()}</span>
      ),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-white/30" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/vendor/organizations/${props.row.original.id}`}
                    >
                      Vendor View
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
                    }
                  >
                    <Link
                      className="text-pink/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Delete
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: organizations,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <div className="">
        <div className="mx-auto">
          <div className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-white/60">
                    Organizations
                  </h1>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <Link
                    to={`/admin/organizations/new`}
                    className=" -translate-y-full sm:translate-y-0 flex items-center rounded-md bg-pink px-3 py-2 text-center text-sm font-semibold text-white hover:bg-purple"
                  >
                    <PlusIcon className="h-5 mr-1" />
                    Add Organization
                  </Link>
                </div>
              </div>
              <div className="mt-4 px-6 py-8 rounded-lg border-borderAdmin border flex justify-center items-center">
    

                <form onSubmit={handleSubmit}>
                  <div className="flex items-center relative gap-2 justify-center">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Organization Name"
                      className="w-72 focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                    />
                    <button
                      type="submit"
                      className="h-10 bg-purple rounded-lg px-10 text-white"
                    >
                      Search
                    </button>
                    <span className="text-yellow absolute left-0 text-xs -bottom-6">
                      {error}
                    </span>
                  </div>
                </form>



              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-visible sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    {organizations ? (
                      <table className="min-w-full divide-y divide-borderAdmin">
                        <thead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr className="text-white/50" key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th className="text-left" key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody className="divide-y divide-borderAdmin">
                          {table?.getRowModel()?.rows?.map((row) => {
                            console.log(row);
                            return (
                              <tr className="h-11" key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      className="py-1 text-white/30"
                                      key={cell.id}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
