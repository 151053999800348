import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Fragment, useState } from "react";
import { format } from "date-fns";
import { Payout, PayoutStatus } from "../../../types/Payout";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { formatCentsToDollars } from "../../../utils/currency";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  BanknotesIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from "@stripe/connect-js";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { Button } from "../../../components/reusable/Button";
import { PayoutAccount } from "../../../types/PayoutAccount";
import { is } from "date-fns/locale";
import { Spinner } from "../../../components/reusable/Spinner";
import { HappyTooltip } from "../../../components/reusable/Tooltip";
import { useNotification } from "../../../providers/NotificationContext";
import { sum } from "lodash";
import { Modal } from "../../../components/reusable/Modal";
import { classNames } from "../../../utils/styles";

type SearchFormQuery = {
  paymentId: string;
  eventName: string;
  userName: string;
  dateFrom: Date | null;
  dateTo: Date | null;
};

export const PayoutList = () => {
  const { showError, showSuccess } = useNotification();
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const { currentOrganization } = useVendor();
  const [showOnboarding, setShowOnboarding] = useState(false);

  const [searchQuery, setSearchQuery] = useState<SearchFormQuery | null>(null);
  const [stripeConnectSession, setStripeConnectSession] =
    useState<StripeConnectInstance | null>(null);

  const { data: payoutData, refetch: refetchPayouts } = useQuery({
    queryKey: ["payouts", currentOrganization, searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/payouts`,
        params: {
          search: searchQuery,
        },
      }),
    enabled: !!currentOrganization,
    refetchInterval: 10000,
  });

  const payouts = payoutData?.data.payouts;

  if (showOnboarding) {
    return (
      <>
        <div className="flex justify-start mb-5">
          <Button
            variant="link"
            onClick={() => {
              setShowOnboarding(false);
              setStripeConnectSession(null);
            }}
          >
            <ArrowLeftIcon className="h-5 w-5" />
            Go Back
          </Button>
        </div>
        {stripeConnectSession && (
          <VendorCard className="min-h-[70vh] flex items-center justify-center py-6">
            <ConnectComponentsProvider connectInstance={stripeConnectSession}>
              <ConnectAccountOnboarding
                onExit={() => {
                  setShowOnboarding(false);
                }}
              />
            </ConnectComponentsProvider>
          </VendorCard>
        )}
      </>
    );
  }
  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
          <BanknotesIcon className="h-7 w-7" />
          Payouts & Accounts
        </h1>
      </div>
      <div className="flex flex-col xl:flex-row xl:gap-5">
        <BankingHeader
          setShowPayoutModal={setShowPayoutModal}
          setShowOnboarding={setShowOnboarding}
          setStripeConnectSession={setStripeConnectSession}
        />

        {payouts && payouts.length ? (
          <VendorCard className="mt-6 grow">
            <PayoutTable payouts={payouts} />
          </VendorCard>
        ) : (
          <VendorCard className="flex justify-center items-center h-20 mt-6">
            <span>No payouts yet</span>
          </VendorCard>
        )}
      </div>
      <PayoutModal
        isOpen={showPayoutModal}
        onRequestClose={() => {
          setShowPayoutModal(false)
          refetchPayouts()
        }}
      />
    </>
  );
};

export const PayoutTable = ({ payouts }: { payouts: Payout[] }) => {
  const columnHelper = createColumnHelper<Payout>();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor("amount_cents", {
      header: "Payout Amount",
      cell: (info) => {
        const amount = info.getValue() as number;
        return formatCentsToDollars(amount);
      },
    }),
    columnHelper.accessor("payout_date", {
      header: "Date",
      cell: (info) => {
        const date = info.getValue() as string;
        return format(new Date(date), "MM/dd/yyyy");
      },
    }),
    columnHelper.accessor("payout_method", {
      header: "Method",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => {
        const status: PayoutStatus = info.getValue()
        const classes = "px-3 py-1 rounded-full text-xs font-semibold capitalize"
        let color = "bg-blue/20 text-blue"
        if (status === "paid") {
          color = "bg-green-500/20 text-green-500"
        } else if (status === "failed" || status === "canceled") {
          color = "bg-red-500/20 text-red-500"
        } else if (status === "pending") {
          color = "bg-blue/20 text-blue"
        }
        return (
          <span className={classNames(
            classes,
            color
          )}>
            {status}
          </span>
        )
      },
    }),
    columnHelper.accessor("notes", {
      header: "Notes",
      cell: (info) => info.getValue(),
    })
    // columnHelper.display({
    //   id: "actions",
    //   cell: (props) => {
    //     return (
    //       <div className="flex">
    //         <Menu as="div" className={"relative inline-block"}>
    //           <Menu.Button className="bg-transparent flex items-center justify-center">
    //             <Cog6ToothIcon className="h-6 text-white/30" />
    //           </Menu.Button>
    //           <Transition
    //             as={Fragment}
    //             enter="transition ease-out duration-100"
    //             enterFrom="transform opacity-0 scale-95"
    //             enterTo="transform opacity-100 scale-100"
    //             leave="transition ease-in duration-75"
    //             leaveFrom="transform opacity-100 scale-100"
    //             leaveTo="transform opacity-0 scale-95"
    //           >
    //             <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
    //               <Menu.Item
    //                 as={"div"}
    //                 className={"px-3 py-2 hover:bg-white/10"}
    //               >
    //                 <Link
    //                   className="text-white/60 block"
    //                   to={`/admin/organizations/${props.row.original.id}`}
    //                 >
    //                   Edit
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item
    //                 as={"div"}
    //                 className={
    //                   "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
    //                 }
    //               >
    //                 <Link
    //                   className="text-pink/60 block"
    //                   to={`/admin/organizations/${props.row.original.id}`}
    //                 >
    //                   Delete
    //                 </Link>
    //               </Menu.Item>
    //             </Menu.Items>
    //           </Transition>
    //         </Menu>
    //       </div>
    //     );
    //   },
    // }),
  ];

  const table = useReactTable({
    data: payouts,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <span className="text-lg font-bold flex items-center gap-3 mb-5">
        Payouts
      </span>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-300">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const BankingHeader = ({
  setStripeConnectSession,
  setShowOnboarding,
  setShowPayoutModal,
}: {
  setStripeConnectSession: (stripe_instance: StripeConnectInstance) => void;
  setShowOnboarding: (show: boolean) => void;
  setShowPayoutModal: (show: boolean) => void;
}) => {
  const { currentOrganization, refetchOrganization } = useVendor();

  const { data: balanceData } = useQuery({
    queryKey: ["account_balance", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/account_balance`,
        method: "GET",
      }),
    enabled: !!currentOrganization,
    refetchInterval: 10000,
  });

  const {
    data: accountData,
    refetch: refetchAccounts,
    isFetching: fetchingAccounts,
  } = useQuery({
    queryKey: ["accounts", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/bank_accounts`,
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });
  const balance: Balance = balanceData?.data.balance;
  const accounts = accountData?.data;
  const activeAccount = accounts?.find(
    (account: PayoutAccount) => account.active
  );
  const connectedAccount = currentOrganization?.connected_account;
  const totalAvailableBalance = sum(balance?.available.map((b) => b.amount));
  const fetchConnectSession = async (stripeAccountID?: string) => {
    const response = await makeApiRequest({
      path: "/vendor/bank_accounts",
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        stripe_account_id: stripeAccountID,
      },
    });

    if (response.status === 201) {
      console.log(response);
      const { stripe_connect_session } = response.data;
      setStripeConnectSession(
        loadConnectAndInitialize({
          publishableKey: import.meta.env.VITE_STRIPE_KEY,
          fetchClientSecret: () => stripe_connect_session.client_secret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
      setShowOnboarding(true);
      refetchOrganization();
    }
  };

  return (
    <div>
      {connectedAccount ? (
        <VendorCard className="mt-6 w-fit bg-blue sticky">
          <div className="flex justify-start gap-10">
            <div className="flex flex-col items-center justify-center gap-3">
              <span className="text-sm font-semibold flex gap-1">
                Available Balance

                <HappyTooltip anchor="top" content="The funds available to be withdrawn.">
                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                </HappyTooltip>
              </span>
              <span className="text-3xl font-bold text-green-500">
                {formatCentsToDollars(totalAvailableBalance)}
              </span>

              <Button onClick={() => setShowPayoutModal(true)} variant="blue">
                <BanknotesIcon className="h-5 w-5" />
                Withdraw Funds
              </Button>
            </div>

            <div className="flex flex-col justify-between items-center">
              <span className="text-sm font-semibold flex gap-1">
                Payout Account
                <HappyTooltip anchor="top" content="Funds that are withdrawn go here.">
                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                </HappyTooltip>  
              </span>
              {activeAccount ? (
                <div className="rounded-lg border border-gray-300 bg-blue/10 shadow-sm p-4 flex flex-col items-center">
                  <span className="text-blue font-semibold">
                    {activeAccount.bank_name}
                  </span>
                  <span className="text-sm text-gray-500">
                    Account ending in {activeAccount.bank_last4}
                  </span>
                </div>
              ) : (
                <Button
                  onClick={() =>
                    fetchConnectSession(connectedAccount.stripe_account_id)
                  }
                >
                  <Cog6ToothIcon className="h-5 w-5" />
                  Finish Setup
                </Button>
              )}
            </div>
          </div>
        </VendorCard>
      ) : (
        <div className="p-3 flex items-center justify-center bg-blue/10 font-semibold text-blue border border-blue/10 rounded-lg mt-5">
          <div className="flex items-center justify-center flex-col gap-5">
            <span className="text-lg font-semibold">
              It looks like you haven't connected a bank account yet. Let's fix
              that.
            </span>

            <Button
              className="flex gap-3 items-center"
              variant="blue"
              onClick={() => fetchConnectSession()}
            >
              <BanknotesIcon className="h-5 w-5" />
              Connect Bank Account
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const PayoutModal = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const {currentOrganization} = useVendor();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const { showError, showSuccess } = useNotification();

  const handleFormSubmit = async () => {
    setLoading(true);
    const response = await makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/payouts`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        payout: {
          organization_id: currentOrganization?.id,
          amount_cents: amount*100,
          notes,
          payout_method: 'stripe',
          payout_date: new Date()
        },
      },
    });
    setLoading(false);
    if (response.status === 201) {
      showSuccess("Payout request submitted successfully");
      onRequestClose();
    } else {
      showError("An error occurred. Please try again later");
    }
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="flex flex-col gap-5">
        <span className="text-lg font-semibold">Withdraw Funds</span>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-3">
            <span className="text-sm font-semibold">Amount</span>
            <input
              onChange={(e) => setAmount(parseInt(e.target.value))}
              name="amount"
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter Amount"
            />
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-sm font-semibold">Notes</span>
            <textarea
              onChange={(e) => setNotes(e.target.value)}
              name="notes"
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter Notes"
            />
          </div>
        </div>
        <Button size="lg" variant="success" onClick={handleFormSubmit} type="submit">
          {loading ? <Spinner /> : "Withdraw Funds"}
        </Button>
      </div>
    </Modal>
  );
};
