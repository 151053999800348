import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import Select, { MultiValue } from "react-select";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import {
  CheckIcon,
  Cog6ToothIcon,
  XMarkIcon,
  TicketIcon,
  ChevronUpDownIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { HappyEvent, PriceLevel, Section, Tier } from "../../../types/Event";
import { format } from "date-fns";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Modal } from "../../../components/reusable/Modal";
import { Field, FieldInputProps, Form, Formik, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { CirclePicker } from "react-color";
import { Button } from "../../../components/reusable/Button";
import { useNotification } from "../../../providers/NotificationContext";
import { formatCentsToDollars } from "../../../utils/currency";
import { useVendor } from "../../../providers/VendorContext";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { PlusIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { SeasonPassGroup } from "../../../types/SeasonPass";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

type ModalFormState = {
  action: "create" | "edit";
  priceLevel: PriceLevel | null;
};
export const PriceLevels = ({ event }: { event: HappyEvent }) => {
  const params = useParams();
  const [priceLevels, setPriceLevels] = useState<PriceLevel[]>([]);
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<PriceLevel>();
  const [modalForm, setModalForm] = useState<ModalFormState | null>();
  // const [editingPriceLevel, setEditingPriceLevel] = useState<PriceLevel | null>(null);
  const { dispatch } = useNotification();
  const { data: priceLevelData, refetch: refetchPriceLevels } = useQuery({
    queryKey: ["priceLevels", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/price_levels",
        params: {
          event_id: params.id,
          organization_id: currentOrganization?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          setPriceLevels(res.data);
          return res.data;
        }
        return [];
      });
    },
    enabled: !!params.id && !!currentOrganization,
    retry: false,
  });

  const deletePriceLevel = (id: number) => {
    makeApiRequest({
      path: `/vendor/price_levels/${id}`,
      params: { organization_id: currentOrganization?.id },
      method: "DELETE",
    }).then((res) => {
      if (res.status === 200) {
        refetchPriceLevels();
        dispatch({
          type: "open",
          payload: {
            level: "success",
            message: "Successfully deleted price level.",
          },
        });
      }
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourcePriceLevel = priceLevels[source.index];
    const newPriceLevels = Array.from(priceLevels);
    newPriceLevels.splice(source.index, 1);
    newPriceLevels.splice(destination.index, 0, sourcePriceLevel);

    setPriceLevels(newPriceLevels);
    makeApiRequest({
      path: `/vendor/price_levels/${sourcePriceLevel.id}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        price_level: { sort_order: destination.index },
      },
    });
  };

  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => {
        return (
          <span
            role="button"
            onClick={() => {
              setModalForm({
                priceLevel: info.row.original as PriceLevel,
                action: "edit",
              });
            }}
            className="text-lightPurple"
          >
            {info.getValue()}
          </span>
        );
      },
    }),
    columnHelper.accessor("section", {
      header: "Section",
      cell: (info) => {
        const tier: Section | undefined = info.renderValue() as
          | Section
          | undefined;
        return tier?.name;
      },
    }),
    columnHelper.accessor("tier", {
      header: "Tier",
      cell: (info) => {
        const tier: Tier | undefined = info.renderValue() as Tier | undefined;
        return tier?.name;
      },
    }),
    columnHelper.accessor("only_visible_in_box_office", {
      header: "Box Office Only",
      cell: (info) => {
        const boxOfficeOnly = info.renderValue();
        return (
          <span>
            {boxOfficeOnly ? (
              <CheckIcon className="h-5 text-green-500" />
            ) : (
              <XMarkIcon className="h-5 text-red-500" />
            )}
          </span>
        );
      },
    }),

    columnHelper.accessor("code", {
      header: "Code",
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor("price_cents", {
      header: "Price",
      cell: (info) => {
        return formatCentsToDollars(info.getValue());
      },
    }),
    columnHelper.accessor("ticket_color", {
      header: "Ticket Color",
      cell: (info) => {
        if (!info.getValue()) {
          return <span>N/A</span>;
        }
        return (
          <span
            style={{ backgroundColor: info.getValue() }}
            className="flex items-center justify-center p-3 w-5 h-5"
          />
        );
      },
    }),
    columnHelper.accessor("ticket_count", {
      header: "Tickets",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <MenuButton className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <MenuItem
                    as={"div"}
                    className={"px-3 py-2 cursor-pointer hover:bg-white/10"}
                    onClick={() =>
                      setModalForm({
                        priceLevel: props.row.original as PriceLevel,
                        action: "edit",
                      })
                    }
                  >
                    <button className="text-gray-500 block">Edit</button>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 cursor-pointer border-gray-200 border-t"
                    }
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this price level?"
                        )
                      ) {
                        deletePriceLevel(props.row.original.id);
                      }
                    }}
                  >
                    <button className="text-pink/60 block">Delete</button>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];
  const table = useReactTable({
    data: priceLevels,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSuccessfulUpdate = () => {
    dispatch({
      type: "open",
      payload: {
        level: "success",
        message: "Successfully updated price level!",
      },
    });
    setModalForm(null);
    refetchPriceLevels();
  };

  return (
    <>
      <VendorCard className="mt-5">
        {priceLevels && priceLevels.length ? (
          <>
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-gray-500">Price Levels</h3>
              <Button
                onClick={() => {
                  setModalForm({ action: "create", priceLevel: null });
                }}
                size="sm"
                variant="blue"
              >
                <PlusIcon className="w-4" />
                Add Price Level
              </Button>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <table className="min-w-full divide-y divide-gray-200 mt-5">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr className="text-gray-500 text-sm" key={headerGroup.id}>
                      <th></th>
                      {headerGroup.headers.map((header) => (
                        <th className="text-left" key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <Droppable droppableId="priceLevels">
                  {(provided) => (
                    <tbody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="divide-y divide-gray-200"
                    >
                      {table?.getRowModel()?.rows?.map((row, index) => {
                        return (
                          <Draggable
                            key={index}
                            draggableId={`${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                className="h-11 text-sm"
                                key={row.original.id}
                              >
                                <td>
                                  <div {...provided.dragHandleProps}>
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-300" />
                                  </div>
                                </td>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      className="py-1 text-gray-400"
                                      key={cell.id}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          </>
        ) : (
          <div className="w-ful flex items-center justify-center">
            <div className="max-w-lg py-5 px-16 flex flex-col gap-2 justify-center items-center">
              <div className="relative">
                <CurrencyDollarIcon className="h-10 text-gray-300" />
                <PlusCircleIcon className="h-5 text-gray-500 absolute right-0 bottom-0" />
              </div>
              <span className="text-xs font-bold">No Price Levels</span>
              <span className="text-xs">
                {" "}
                Get started by adding a price level!
              </span>
              <Button
                onClick={() => {
                  setModalForm({ action: "create", priceLevel: null });
                }}
                variant="pink"
                className="flex items-center gap-1 mt-5"
              >
                <PlusIcon className="w-4" /> Add Price Level
              </Button>
            </div>
          </div>
        )}
      </VendorCard>

      <Modal
        title={
          modalForm?.priceLevel ? "Editing Price Level" : "Create a Price Level"
        }
        onRequestClose={() => setModalForm(null)}
        isOpen={!!modalForm}
      >
        <>
          <PriceLevelForm
            onSuccessfulUpdate={handleSuccessfulUpdate}
            priceLevel={modalForm?.priceLevel}
            event={event}
          />
        </>
      </Modal>
    </>
  );
};

export const PriceLevelForm = ({
  priceLevel,
  event,
  onSuccessfulUpdate,
}: {
  priceLevel?: PriceLevel | null;
  event: HappyEvent;
  onSuccessfulUpdate: () => void;
}) => {
  const { currentOrganization } = useVendor();
  const { showError } = useNotification();

  const { data: tiers } = useQuery({
    queryKey: ["tiers", event.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/tiers",
        params: {
          organization_id: currentOrganization?.id,
          event_id: event.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return [];
      });
    },
    enabled: !!event && !!currentOrganization,
    retry: false,
  });

  const { data: sections } = useQuery({
    queryKey: ["sections", event.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/sections",
        params: {
          organization_id: currentOrganization?.id,
          event_id: event.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return [];
      });
    },
    enabled: !!event && !!currentOrganization,
    retry: false,
  });

  const { data: seasonPassGroups } = useQuery({
    queryKey: ["seasonPassGroups", currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/season_pass_groups",
        params: {
          organization_id: currentOrganization?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return [];
      });
    },
    enabled: !!currentOrganization,
    retry: false,
  });

  return (
    <Formik
      initialValues={
        {
          name: priceLevel?.name ?? "",
          tier_id: priceLevel?.tier.id ?? "",
          section_id: priceLevel?.section.id ?? "",
          code: priceLevel?.code ?? "",
          ticket_color: priceLevel?.ticket_color ?? "",
          description: priceLevel?.description ?? "",
          price_dollars: priceLevel?.price_cents
            ? priceLevel.price_cents / 100
            : 0,
          only_visible_in_box_office:
            priceLevel?.only_visible_in_box_office ?? false,
          season_pass_group_ids: (priceLevel?.season_pass_groups || []).map(
            (group) => ({
              label: group.name,
              value: group.id,
            })
          ),
        } as PriceLevelFormProps
      }
      onSubmit={(data) => {
        const payload = {
          ...data,
          event_id: event.id,
          price_cents: data.price_dollars * 100,
          season_pass_group_ids: data.season_pass_group_ids.map(
            (group: SelectOption) => group.value
          ),
        };
        const apiPath = priceLevel
          ? `/vendor/price_levels/${priceLevel?.id}`
          : "/vendor/price_levels";
        makeApiRequest({
          path: apiPath,
          method: priceLevel ? "PUT" : "POST",
          params: {
            price_level: payload,
            organization_id: currentOrganization?.id,
          },
        })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              onSuccessfulUpdate();
            }
          })
          .catch((err) => {
            showError(err.response.data);
          });
      }}
    >
      <PriceLevelFormContent
        seasonPassGroups={seasonPassGroups}
        tiers={tiers}
        sections={sections}
      />
    </Formik>
  );
};
type PriceLevelFormProps = {
  price_dollars: number;
  name: string;
  code: string;
  tier_id: string;
  section_id: string;
  ticket_color: string;
  description: string;
  only_visible_in_box_office: boolean;
  season_pass_group_ids: SelectOption[];
};
export const PriceLevelFormContent = ({
  seasonPassGroups,
  tiers,
  sections,
}: {
  seasonPassGroups: SeasonPassGroup[];
  tiers: Tier[];
  sections: Section[];
}) => {
  const formik = useFormikContext<PriceLevelFormProps>();
  console.log(formik.values);
  return (
    <Form className="flex flex-col gap-4 py-5">
      <div className="flex justify-between gap-2">
        <Field name="name">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Price Level Name</label>
              <input
                placeholder="Price Level Name"
                className="h-10 border-none rounded-lg bg-gray-100"
                {...field}
                type="text"
              />
            </div>
          )}
        </Field>

        <Field name="price_dollars">
          {({ field }: { field: FieldInputProps<number> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Price</label>
              <input
                {...field}
                type="number"
                step={0.01}
                min={0.0}
                placeholder="Price"
                className="h-10 border-none rounded-lg bg-gray-100"
                value={field.value}
              />
            </div>
          )}
        </Field>
      </div>

      <div className="flex justify-between gap-2">
        <Field name="tier_id">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Tier</label>
              <select
                className="h-10 border-none rounded-lg bg-gray-100"
                {...field}
              >
                <option value="">Select Tier</option>
                {tiers?.map((tier) => (
                  <option key={tier.id} value={tier.id}>
                    {tier.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Field>

        <Field name="section_id">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Section</label>
              <select
                className="h-10 border-none rounded-lg bg-gray-100"
                {...field}
              >
                <option value="">Select Section</option>
                {sections?.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Field>
      </div>
      <div className="flex justify-between gap-2">
        <Field name="ticket-color">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label className="flex gap-2 items-center">
                Choose Ticket Color{" "}
                <TicketIcon
                  className="w-6 h-6"
                  style={{ color: formik.values.ticket_color }}
                />
              </label>
              <CirclePicker
                color={formik.values.ticket_color}
                onChange={(color) => {
                  formik.setFieldValue("ticket_color", color.hex);
                }}
              />
            </div>
          )}
        </Field>
        <div className="w-1/2">
          <Field name="only_visible_in_box_office">
            {({ field }: { field: FieldInputProps<string> }) => (
              <div className="flex flex-col gap-2 w-full">
                <label>Box Office Only</label>
                <select
                  className="h-10 border-none rounded-lg bg-gray-100"
                  value={field.value}
                  onChange={(e) =>
                    formik.setFieldValue(field.name, e.target.value === "true")
                  }
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            )}
          </Field>
          <Field name="code">
            {({ field }: { field: FieldInputProps<never> }) => (
              <div className="flex flex-col gap-2 w-full mt-4">
                <label>Code</label>
                <input
                  placeholder="Code"
                  className="h-10 border-none rounded-lg bg-gray-100"
                  {...field}
                  type="text"
                />
              </div>
            )}
          </Field>
        </div>
      </div>
      <div>
        <Field name="description">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-full">
              <label>Description</label>
              <textarea
                className="border-none rounded-lg bg-gray-100"
                rows={3} 
                {...field}
              />
            </div>
          )}
        </Field>
      </div>
      <div>
        <SeasonPassGroupMultiSelect seasonPassGroups={seasonPassGroups} />
      </div>
      <div>
        <Button variant="success" type="submit" className="w-full mt-2">
          Save Price Level
        </Button>
      </div>
    </Form>
  );
};

type SelectOption = {
  label: string;
  value: number;
};

export const SeasonPassGroupMultiSelect = ({
  seasonPassGroups,
}: {
  seasonPassGroups: SeasonPassGroup[];
}) => {
  const formik = useFormikContext();
  const options: SelectOption[] = seasonPassGroups?.map((group) => ({
    label: group.name,
    value: group.id,
  }));
  return (
    <Field name="season_pass_group_ids">
      {({ field }: { field: FieldInputProps<never> }) => (
        <div>
          <label className="my-2 block">Select Season Pass Groups</label>
          <Select
            {...field}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                height: "40px",
                border: "1px solid #d2d5db",
              }),
              multiValueLabel: (labelStyles) => ({
                ...labelStyles,
                textOverflow: "",
              }),
            }}
            onChange={(selectedOptions) => {
              formik.setFieldValue("season_pass_group_ids", selectedOptions);
            }}
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={undefined}
            isMulti
            options={options}
          />
        </div>
      )}
    </Field>
  );
};
